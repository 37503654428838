import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import { ChefHat, Edit, Trash2, DollarSign, Package, Users, Star, Clock, Tag, Plus } from 'lucide-react';
import type { Meal, Order } from '../types/database';
import toast from 'react-hot-toast';

export default function CookDashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [meals, setMeals] = useState<Meal[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    monthlyEarnings: 0,
    pendingOrders: 0,
    totalCustomers: 0,
    averageRating: 0
  });

  useEffect(() => {
    const loadDashboardData = async () => {
      if (!user) return;

      try {
        // Load meals
        const mealsRef = collection(db, 'meals');
        const mealsQuery = query(mealsRef, where('cook_id', '==', user.user_id));
        const mealsSnap = await getDocs(mealsQuery);
        const mealsData = mealsSnap.docs.map(doc => ({
          ...doc.data(),
          meal_id: doc.id
        })) as Meal[];
        setMeals(mealsData);

        // Load orders
        const ordersRef = collection(db, 'orders');
        const ordersQuery = query(ordersRef, where('cook_id', '==', user.user_id));
        const ordersSnap = await getDocs(ordersQuery);
        const ordersData = ordersSnap.docs.map(doc => ({
          ...doc.data(),
          order_id: doc.id
        })) as Order[];
        setOrders(ordersData);

        // Calculate statistics
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        
        const monthlyOrders = ordersData.filter(order => 
          new Date(order.created_at) >= firstDayOfMonth
        );

        const monthlyEarnings = monthlyOrders.reduce(
          (total, order) => total + order.total_amount,
          0
        );

        const pendingOrders = ordersData.filter(
          order => ['pending', 'confirmed', 'preparing'].includes(order.order_status)
        ).length;

        const uniqueCustomers = new Set(ordersData.map(order => order.consumer_id)).size;

        setStats({
          monthlyEarnings,
          pendingOrders,
          totalCustomers: uniqueCustomers,
          averageRating: 4.5 // To be implemented with real data
        });
      } catch (error) {
        console.error('Error loading dashboard data:', error);
        toast.error('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadDashboardData();
  }, [user]);

  const handleDeleteMeal = async (mealId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce plat ?')) return;

    try {
      await deleteDoc(doc(db, 'meals', mealId));
      setMeals(meals.filter(meal => meal.meal_id !== mealId));
      toast.success('Plat supprimé avec succès');
    } catch (error) {
      console.error('Error deleting meal:', error);
      toast.error('Erreur lors de la suppression du plat');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4285f4]"></div>
      </div>
    );
  }

  const renderOverview = () => (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Revenus mensuels</p>
              <p className="text-2xl font-bold text-[#4285f4]">{stats.monthlyEarnings.toFixed(2)}€</p>
            </div>
            <DollarSign className="w-8 h-8 text-[#4285f4]" />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Commandes en attente</p>
              <p className="text-2xl font-bold text-[#4285f4]">{stats.pendingOrders}</p>
            </div>
            <Package className="w-8 h-8 text-[#4285f4]" />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Clients totaux</p>
              <p className="text-2xl font-bold text-[#4285f4]">{stats.totalCustomers}</p>
            </div>
            <Users className="w-8 h-8 text-[#4285f4]" />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Note moyenne</p>
              <p className="text-2xl font-bold text-[#4285f4]">{stats.averageRating.toFixed(1)}</p>
            </div>
            <Star className="w-8 h-8 text-[#4285f4]" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold">Dernières commandes</h3>
            <Button variant="outline" size="sm" onClick={() => setActiveTab('orders')}>
              Voir tout
            </Button>
          </div>
          {orders.length === 0 ? (
            <p className="text-gray-600">Aucune commande pour le moment</p>
          ) : (
            <div className="space-y-4">
              {orders.slice(0, 5).map((order) => (
                <div key={order.order_id} className="flex items-center justify-between">
                  <div>
                    <p className="font-medium">Commande #{order.order_id.slice(-8)}</p>
                    <p className="text-sm text-gray-600">
                      {new Date(order.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm font-medium capitalize
                    ${order.order_status === 'delivered' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}
                  >
                    {order.order_status}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold">Plats populaires</h3>
            <Button variant="outline" size="sm" onClick={() => setActiveTab('meals')}>
              Voir tout
            </Button>
          </div>
          {meals.length === 0 ? (
            <p className="text-gray-600">Aucun plat pour le moment</p>
          ) : (
            <div className="space-y-4">
              {meals.slice(0, 5).map((meal) => (
                <div key={meal.meal_id} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src={meal.image_url || "https://images.unsplash.com/photo-1546069901-ba9599a7e63c"}
                      alt={meal.name}
                      className="w-12 h-12 object-cover rounded-lg mr-4"
                    />
                    <div>
                      <p className="font-medium">{meal.name}</p>
                      <p className="text-sm text-gray-600">{meal.price.toFixed(2)}€</p>
                    </div>
                  </div>
                  <span className="text-sm text-gray-600">
                    {meal.portions_available} en stock
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderMeals = () => (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold">Vos Plats</h2>
          <p className="text-sm text-gray-600">
            {meals.length} plat{meals.length > 1 ? 's' : ''} au total
          </p>
        </div>
        <Button onClick={() => navigate('/create-meal')}>
          <Plus className="w-4 h-4 mr-2" />
          Ajouter un plat
        </Button>
      </div>

      {meals.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <ChefHat className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600 mb-4">Vous n'avez pas encore de plats</p>
          <Button onClick={() => navigate('/create-meal')}>
            Ajouter votre premier plat
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {meals.map((meal) => (
            <div key={meal.meal_id} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="relative">
                <img
                  src={meal.image_url || "https://images.unsplash.com/photo-1546069901-ba9599a7e63c"}
                  alt={meal.name}
                  className="w-full h-48 object-cover"
                />
                {meal.portions_available <= 0 && (
                  <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                    <span className="text-white font-semibold text-lg">Épuisé</span>
                  </div>
                )}
                {meal.portions_available > 0 && meal.portions_available <= 3 && (
                  <div className="absolute top-2 right-2 px-3 py-1 bg-red-500 text-white text-sm font-medium rounded-full">
                    Plus que {meal.portions_available} disponible{meal.portions_available > 1 ? 's' : ''}
                  </div>
                )}
                <div className="absolute bottom-2 right-2 px-3 py-1 bg-white/90 rounded-full text-sm font-medium">
                  {meal.price.toFixed(2)}€
                </div>
              </div>
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">{meal.name}</h3>
                <p className="text-gray-600 text-sm mb-4 line-clamp-2">{meal.description}</p>
                
                <div className="space-y-2 mb-4">
                  <div className="flex items-center text-sm text-gray-600">
                    <Clock className="w-4 h-4 mr-2" />
                    <span>{meal.preparation_time} minutes de préparation</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-600">
                    <Package className="w-4 h-4 mr-2" />
                    <span>{meal.portions_available} portion{meal.portions_available > 1 ? 's' : ''} en stock</span>
                  </div>
                  {meal.category && (
                    <div className="flex items-center text-sm text-gray-600">
                      <Tag className="w-4 h-4 mr-2" />
                      <span>{meal.category}</span>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between pt-4 border-t">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => navigate(`/meals/${meal.meal_id}/edit`)}
                  >
                    <Edit className="w-4 h-4 mr-2" />
                    Modifier
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleDeleteMeal(meal.meal_id)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Supprimer
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderOrders = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Commandes</h2>
      
      {orders.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600">Aucune commande pour le moment</p>
        </div>
      ) : (
        <div className="space-y-4">
          {orders.map((order) => (
            <div key={order.order_id} className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="font-semibold">Commande #{order.order_id.slice(-8)}</h3>
                  <p className="text-sm text-gray-600">
                    {new Date(order.created_at).toLocaleDateString()}
                  </p>
                </div>
                <span className={`px-3 py-1 rounded-full text-sm font-medium capitalize
                  ${order.order_status === 'delivered' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}
                >
                  {order.order_status}
                </span>
              </div>

              <div className="border-t pt-4">
                <p className="font-medium">Total: {order.total_amount.toFixed(2)}€</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Tableau de Bord</h1>
      </div>

      <div className="mb-8">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-8">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'overview'
                  ? 'border-[#4285f4] text-[#4285f4]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Aperçu
            </button>
            <button
              onClick={() => setActiveTab('meals')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'meals'
                  ? 'border-[#4285f4] text-[#4285f4]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Plats
            </button>
            <button
              onClick={() => setActiveTab('orders')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'orders'
                  ? 'border-[#4285f4] text-[#4285f4]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Commandes
            </button>
          </nav>
        </div>
      </div>

      <div>
        {activeTab === 'overview' && renderOverview()}
        {activeTab === 'meals' && renderMeals()}
        {activeTab === 'orders' && renderOrders()}
      </div>
    </div>
  );
}