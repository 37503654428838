import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
  writeBatch,
  increment,
  DocumentReference,
  DocumentSnapshot
} from 'firebase/firestore';
import { db, collections, retryOperation } from './firebase';
import type { User, Cart, CartItem, Meal, Order, OrderItem, CookProfile } from '../types/database';
import toast from 'react-hot-toast';

// Cache for meal data
const mealCache = new Map<string, { meal: Meal; timestamp: number }>();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Helper function to get meal with caching
const getCachedMeal = async (mealId: string): Promise<Meal | null> => {
  const cached = mealCache.get(mealId);
  const now = Date.now();

  if (cached && now - cached.timestamp < CACHE_DURATION) {
    return cached.meal;
  }

  try {
    const mealRef = doc(db, collections.meals, mealId);
    const mealSnap = await getDoc(mealRef);
    
    if (mealSnap.exists()) {
      const meal = { ...mealSnap.data(), meal_id: mealSnap.id } as Meal;
      mealCache.set(mealId, { meal, timestamp: now });
      return meal;
    }
    return null;
  } catch (error) {
    console.error('Error fetching meal:', error);
    throw error;
  }
};

// User Operations
export const getUser = async (userId: string): Promise<User | null> => {
  try {
    return await retryOperation(async () => {
      const userRef = doc(db, collections.users, userId);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        return { ...userSnap.data(), user_id: userSnap.id } as User;
      }
      return null;
    });
  } catch (error) {
    console.error('Error in getUser:', error);
    if (error.code !== 'unavailable') {
      toast.error('Erreur lors du chargement des données utilisateur');
    }
    throw error;
  }
};

export const createUser = async (userId: string, userData: Omit<User, 'user_id' | 'created_at' | 'updated_at'>) => {
  try {
    return await retryOperation(async () => {
      const userRef = doc(db, collections.users, userId);
      const user: User = {
        user_id: userId,
        ...userData,
        created_at: new Date(),
        updated_at: new Date(),
      };
      
      await setDoc(userRef, {
        ...user,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      if (userData.user_type === 'cook') {
        await createCookProfile(userId);
      }
      
      return user;
    });
  } catch (error) {
    console.error('Error in createUser:', error);
    toast.error('Erreur lors de la création du compte');
    throw error;
  }
};

export const updateUser = async (userId: string, userData: Partial<User>) => {
  try {
    return await retryOperation(async () => {
      const userRef = doc(db, collections.users, userId);
      await updateDoc(userRef, {
        ...userData,
        updated_at: serverTimestamp()
      });
      return true;
    });
  } catch (error) {
    console.error('Error updating user:', error);
    toast.error('Erreur lors de la mise à jour du profil');
    throw error;
  }
};

export const updateUserType = async (userId: string, newType: 'cook' | 'consumer') => {
  try {
    return await retryOperation(async () => {
      const userRef = doc(db, collections.users, userId);
      await updateDoc(userRef, {
        user_type: newType,
        updated_at: serverTimestamp()
      });

      if (newType === 'cook') {
        await createCookProfile(userId);
      }

      return true;
    });
  } catch (error) {
    console.error('Error updating user type:', error);
    toast.error('Erreur lors du changement de type d\'utilisateur');
    throw error;
  }
};

// Cook Profile Operations
export const createCookProfile = async (userId: string) => {
  try {
    return await retryOperation(async () => {
      const cookProfileRef = doc(db, collections.cookProfiles, userId);
      const cookProfile: CookProfile = {
        cook_id: userId,
        user_id: userId,
        specialties: [],
        bio: '',
        availability_days: [],
        rating_average: 0,
        total_reviews: 0,
      };
      
      await setDoc(cookProfileRef, cookProfile);
      return cookProfile;
    });
  } catch (error) {
    console.error('Error in createCookProfile:', error);
    toast.error('Erreur lors de la création du profil cuisinier');
    throw error;
  }
};

// Cart Operations
export const getOrCreateCart = async (userId: string): Promise<Cart> => {
  try {
    return await retryOperation(async () => {
      const cartsRef = collection(db, collections.carts);
      const q = query(cartsRef, where('user_id', '==', userId), limit(1));
      const cartSnap = await getDocs(q);
      
      if (!cartSnap.empty) {
        const cartData = cartSnap.docs[0].data();
        return {
          ...cartData,
          cart_id: cartSnap.docs[0].id,
        } as Cart;
      }

      const newCartRef = doc(collection(db, collections.carts));
      const newCart: Cart = {
        cart_id: newCartRef.id,
        user_id: userId,
        created_at: new Date(),
        updated_at: new Date(),
      };
      
      await setDoc(newCartRef, {
        ...newCart,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });
      
      return newCart;
    });
  } catch (error) {
    console.error('Error in getOrCreateCart:', error);
    toast.error('Erreur lors de la création du panier');
    throw error;
  }
};

export const getCartItems = async (cartId: string): Promise<(CartItem & { meal: Meal })[]> => {
  try {
    return await retryOperation(async () => {
      const cartItemsRef = collection(db, collections.cartItems);
      const q = query(cartItemsRef, where('cart_id', '==', cartId));
      const cartItemsSnap = await getDocs(q);
      
      const cartItems = await Promise.all(
        cartItemsSnap.docs.map(async (doc) => {
          const cartItem = { ...doc.data(), cart_item_id: doc.id } as CartItem;
          const meal = await getCachedMeal(cartItem.meal_id);
          
          if (!meal) {
            // If meal not found, remove the cart item
            await deleteDoc(doc.ref);
            return null;
          }
          
          return { ...cartItem, meal };
        })
      );

      return cartItems.filter((item): item is CartItem & { meal: Meal } => item !== null);
    });
  } catch (error) {
    console.error('Error in getCartItems:', error);
    toast.error('Erreur lors du chargement du panier');
    throw error;
  }
};

export const addToCart = async (cartItemData: Omit<CartItem, 'cart_item_id' | 'added_at'>) => {
  try {
    return await retryOperation(async () => {
      const cartItemsRef = collection(db, collections.cartItems);
      const q = query(
        cartItemsRef,
        where('cart_id', '==', cartItemData.cart_id),
        where('meal_id', '==', cartItemData.meal_id)
      );
      const existingItems = await getDocs(q);

      if (!existingItems.empty) {
        const existingItem = existingItems.docs[0];
        const currentQuantity = existingItem.data().quantity;
        await updateDoc(existingItem.ref, {
          quantity: currentQuantity + cartItemData.quantity,
          updated_at: serverTimestamp(),
        });
        return existingItem.id;
      }

      const cartItemRef = doc(collection(db, collections.cartItems));
      const newCartItem = {
        ...cartItemData,
        cart_item_id: cartItemRef.id,
        added_at: serverTimestamp(),
      };
      await setDoc(cartItemRef, newCartItem);
      return cartItemRef.id;
    });
  } catch (error) {
    console.error('Error in addToCart:', error);
    toast.error('Erreur lors de l\'ajout au panier');
    throw error;
  }
};

export const removeFromCart = async (cartItemId: string) => {
  try {
    return await retryOperation(async () => {
      const cartItemRef = doc(db, collections.cartItems, cartItemId);
      await deleteDoc(cartItemRef);
    });
  } catch (error) {
    console.error('Error in removeFromCart:', error);
    toast.error('Erreur lors de la suppression du panier');
    throw error;
  }
};

export const updateCartItemQuantity = async (cartItemId: string, quantity: number) => {
  try {
    return await retryOperation(async () => {
      const cartItemRef = doc(db, collections.cartItems, cartItemId);
      await updateDoc(cartItemRef, {
        quantity,
        updated_at: serverTimestamp(),
      });
    });
  } catch (error) {
    console.error('Error in updateCartItemQuantity:', error);
    toast.error('Erreur lors de la mise à jour de la quantité');
    throw error;
  }
};

// Meal Operations
export const getMeal = async (mealId: string): Promise<Meal | null> => {
  try {
    return await retryOperation(async () => {
      const mealRef = doc(db, collections.meals, mealId);
      const mealSnap = await getDoc(mealRef);
      
      if (mealSnap.exists()) {
        return { ...mealSnap.data(), meal_id: mealSnap.id } as Meal;
      }
      return null;
    });
  } catch (error) {
    console.error('Error in getMeal:', error);
    toast.error('Erreur lors du chargement du plat');
    throw error;
  }
};

export const createMeal = async (mealData: Omit<Meal, 'meal_id' | 'created_at'>) => {
  try {
    return await retryOperation(async () => {
      const mealRef = doc(collection(db, collections.meals));
      const meal: Meal = {
        meal_id: mealRef.id,
        ...mealData,
        created_at: new Date(),
      };
      
      await setDoc(mealRef, {
        ...meal,
        created_at: serverTimestamp(),
      });
      
      return meal;
    });
  } catch (error) {
    console.error('Error in createMeal:', error);
    toast.error('Erreur lors de la création du plat');
    throw error;
  }
};

// Order Operations
export const createOrder = async (
  orderData: Omit<Order, 'order_id' | 'created_at' | 'updated_at'>,
  cartItems: (CartItem & { meal: Meal })[]
) => {
  const batch = writeBatch(db);
  
  try {
    return await retryOperation(async () => {
      // Check stock availability
      for (const item of cartItems) {
        const mealRef = doc(db, collections.meals, item.meal.meal_id);
        const mealSnap = await getDoc(mealRef);
        
        if (!mealSnap.exists()) {
          throw new Error(`Meal not found: ${item.meal.meal_id}`);
        }

        const currentStock = mealSnap.data().portions_available;
        if (currentStock < item.quantity) {
          throw new Error(`Not enough stock for meal: ${item.meal.name}`);
        }
      }

      // Create order
      const orderRef = doc(collection(db, collections.orders));
      const order: Order = {
        order_id: orderRef.id,
        ...orderData,
        created_at: new Date(),
        updated_at: new Date(),
      };
      
      batch.set(orderRef, {
        ...order,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      // Create order items and update stock
      for (const cartItem of cartItems) {
        const orderItemRef = doc(collection(db, collections.orderItems));
        const orderItem: OrderItem = {
          order_item_id: orderItemRef.id,
          order_id: orderRef.id,
          meal_id: cartItem.meal.meal_id,
          quantity: cartItem.quantity,
          price: cartItem.meal.price,
        };
        
        batch.set(orderItemRef, orderItem);

        // Update stock
        const mealRef = doc(db, collections.meals, cartItem.meal.meal_id);
        batch.update(mealRef, {
          portions_available: increment(-cartItem.quantity),
          updated_at: serverTimestamp()
        });
      }

      await batch.commit();
      return order;
    });
  } catch (error) {
    console.error('Error creating order:', error);
    toast.error('Erreur lors de la création de la commande');
    throw error;
  }
};