import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Star, ChefHat } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import { getUser } from '../../lib/database';
import type { Meal, User } from '../../types/database';

interface MealWithCook extends Meal {
  cook?: User;
}

export default function FeaturedMeals() {
  const { home } = useTranslation();
  const [meals, setMeals] = useState<MealWithCook[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeaturedMeals = async () => {
      try {
        const mealsRef = collection(db, 'meals');
        const q = query(
          mealsRef,
          where('portions_available', '>', 0),
          orderBy('portions_available', 'desc'),
          limit(3)
        );
        
        const snapshot = await getDocs(q);
        const mealsData = snapshot.docs.map(doc => ({
          ...doc.data(),
          meal_id: doc.id,
        })) as Meal[];

        // Charger les informations des cuisiniers
        const mealsWithCooks = await Promise.all(
          mealsData.map(async (meal) => {
            const cook = await getUser(meal.cook_id);
            return { ...meal, cook };
          })
        );
        
        setMeals(mealsWithCooks);
      } catch (error) {
        console.error('Error loading featured meals:', error);
      } finally {
        setLoading(false);
      }
    };

    loadFeaturedMeals();
  }, []);

  if (loading) {
    return (
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3].map((i) => (
                <div key={i} className="bg-gray-100 rounded-xl h-96"></div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (meals.length === 0) {
    return (
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">{home.featuredMeals.title}</h2>
          <p className="text-gray-600 mb-8">{home.featuredMeals.noMeals}</p>
          <Link
            to="/meals"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600"
          >
            {home.featuredMeals.browseAll}
          </Link>
        </div>
      </section>
    );
  }

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">{home.featuredMeals.title}</h2>
          <Link
            to="/meals"
            className="text-orange-500 hover:text-orange-600 font-medium"
          >
            {home.featuredMeals.viewAll}
          </Link>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {meals.map((meal) => (
            <Link key={meal.meal_id} to={`/meals/${meal.meal_id}`} className="group">
              <div className="relative aspect-[4/3] rounded-xl overflow-hidden mb-4">
                <img
                  src={meal.image_url || "https://images.unsplash.com/photo-1546069901-ba9599a7e63c"}
                  alt={meal.name}
                  className="w-full h-full object-cover transition-transform group-hover:scale-105"
                />
                {meal.portions_available < 5 && (
                  <div className="absolute top-2 right-2 px-3 py-1 bg-red-500 text-white text-sm font-medium rounded-full">
                    {home.featuredMeals.onlyLeft.replace('{count}', meal.portions_available.toString())}
                  </div>
                )}
              </div>
              
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {meal.name}
                </h3>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <ChefHat className="w-4 h-4 mr-1" />
                  <span>par {meal.cook?.username || '...'}</span>
                </div>
                
                {meal.rating_average && (
                  <div className="flex items-center space-x-2 mb-2">
                    <Star className="w-5 h-5 text-yellow-400 fill-current" />
                    <span className="font-medium">{meal.rating_average.toFixed(1)}</span>
                  </div>
                )}
                
                <p className="text-lg font-semibold text-gray-900">
                  {meal.price.toFixed(2)}€
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}