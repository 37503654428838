import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { getOrCreateCart, getCartItems, addToCart, removeFromCart, updateCartItemQuantity, getMeal } from '../lib/database';
import type { Cart, CartItem, Meal } from '../types/database';
import toast from 'react-hot-toast';

interface CartContextType {
  cart: Cart | null;
  cartItems: (CartItem & { meal: Meal })[];
  loading: boolean;
  addItem: (mealId: string, quantity: number) => Promise<void>;
  removeItem: (cartItemId: string) => Promise<void>;
  updateQuantity: (cartItemId: string, quantity: number) => Promise<void>;
  clearCart: () => Promise<void>;
  getItemQuantity: (mealId: string) => number;
  refreshCart: () => Promise<void>;
}

const CartContext = createContext<CartContextType | null>(null);

export function CartProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [cart, setCart] = useState<Cart | null>(null);
  const [cartItems, setCartItems] = useState<(CartItem & { meal: Meal })[]>([]);
  const [loading, setLoading] = useState(true);

  const refreshCart = useCallback(async () => {
    if (!cart) return;
    
    try {
      const items = await getCartItems(cart.cart_id);
      setCartItems(items);
    } catch (error) {
      console.error('Error refreshing cart:', error);
      if (error.code !== 'unavailable') {
        toast.error('Erreur lors du rafraîchissement du panier');
      }
    }
  }, [cart]);

  useEffect(() => {
    const loadCart = async () => {
      if (!user) {
        setCart(null);
        setCartItems([]);
        setLoading(false);
        return;
      }

      try {
        const userCart = await getOrCreateCart(user.user_id);
        setCart(userCart);
        await refreshCart();
      } catch (error) {
        console.error('Error loading cart:', error);
        if (error.code !== 'unavailable') {
          toast.error('Erreur lors du chargement du panier');
        }
      } finally {
        setLoading(false);
      }
    };

    loadCart();
  }, [user, refreshCart]);

  const getItemQuantity = useCallback((mealId: string): number => {
    return cartItems.find(item => item.meal_id === mealId)?.quantity || 0;
  }, [cartItems]);

  const addItem = async (mealId: string, quantity: number) => {
    if (!cart || !user) {
      toast.error('Veuillez vous connecter pour ajouter des articles au panier');
      return;
    }

    try {
      const meal = await getMeal(mealId);
      if (!meal) {
        toast.error('Ce plat n\'est plus disponible');
        return;
      }

      const currentCartItem = cartItems.find(item => item.meal_id === mealId);
      const currentQuantity = currentCartItem?.quantity || 0;
      const totalQuantity = currentQuantity + quantity;

      if (totalQuantity > meal.portions_available) {
        toast.error(`Stock insuffisant. Il ne reste que ${meal.portions_available} portion${meal.portions_available > 1 ? 's' : ''}`);
        return;
      }

      if (currentCartItem) {
        await updateCartItemQuantity(currentCartItem.cart_item_id, totalQuantity);
      } else {
        await addToCart({
          cart_id: cart.cart_id,
          meal_id: mealId,
          quantity,
        });
      }

      await refreshCart();
      toast.success(`${quantity} portion${quantity > 1 ? 's' : ''} ajoutée${quantity > 1 ? 's' : ''} au panier`);
    } catch (error) {
      console.error('Error adding item to cart:', error);
      toast.error('Erreur lors de l\'ajout au panier');
    }
  };

  const updateQuantity = async (cartItemId: string, quantity: number) => {
    try {
      const cartItem = cartItems.find(item => item.cart_item_id === cartItemId);
      if (!cartItem) throw new Error('Cart item not found');

      const meal = await getMeal(cartItem.meal_id);
      if (!meal) throw new Error('Meal not found');

      if (quantity > meal.portions_available) {
        toast.error(`Stock insuffisant. Il ne reste que ${meal.portions_available} portion${meal.portions_available > 1 ? 's' : ''}`);
        return;
      }

      if (quantity <= 0) {
        await removeItem(cartItemId);
      } else {
        await updateCartItemQuantity(cartItemId, quantity);
        await refreshCart();
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
      toast.error('Erreur lors de la mise à jour de la quantité');
    }
  };

  const removeItem = async (cartItemId: string) => {
    try {
      await removeFromCart(cartItemId);
      await refreshCart();
      toast.success('Article retiré du panier');
    } catch (error) {
      console.error('Error removing item from cart:', error);
      toast.error('Erreur lors de la suppression de l\'article');
    }
  };

  const clearCart = async () => {
    if (!cart) return;
    
    try {
      await Promise.all(cartItems.map(item => removeFromCart(item.cart_item_id)));
      setCartItems([]);
      toast.success('Panier vidé');
    } catch (error) {
      console.error('Error clearing cart:', error);
      toast.error('Erreur lors du vidage du panier');
    }
  };

  return (
    <CartContext.Provider 
      value={{ 
        cart, 
        cartItems, 
        loading, 
        addItem, 
        removeItem, 
        updateQuantity, 
        clearCart,
        getItemQuantity,
        refreshCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}