import { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  User as FirebaseUser
} from 'firebase/auth';
import { auth } from '../lib/firebase';
import { getUser, createUser } from '../lib/database';
import type { User } from '../types/database';
import toast from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: Error | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, userData: Omit<User, 'user_id' | 'created_at' | 'updated_at'>) => Promise<void>;
  signOut: () => Promise<void>;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const MAX_RETRIES = 3;

  const loadUserData = async (firebaseUser: FirebaseUser) => {
    try {
      const userData = await getUser(firebaseUser.uid);
      setUser(userData);
      setError(null);
    } catch (error: any) {
      console.error('Error loading user data:', error);
      if (error.code === 'unavailable' && retryAttempt < MAX_RETRIES) {
        setRetryAttempt(prev => prev + 1);
        setTimeout(() => loadUserData(firebaseUser), 2000 * (retryAttempt + 1));
      } else {
        setError(error);
        if (error.code !== 'unavailable') {
          toast.error('Erreur de connexion. Certaines fonctionnalités peuvent être limitées.');
        }
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        await loadUserData(firebaseUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const refreshUser = async () => {
    if (!auth.currentUser) return;
    await loadUserData(auth.currentUser);
  };

  const signIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError(null);
    } catch (error: any) {
      console.error('Sign in error:', error);
      setError(error);
      throw error;
    }
  };

  const signUp = async (
    email: string, 
    password: string, 
    userData: Omit<User, 'user_id' | 'created_at' | 'updated_at'>
  ) => {
    try {
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);
      await createUser(firebaseUser.uid, userData);
      setError(null);
    } catch (error: any) {
      console.error('Sign up error:', error);
      setError(error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setError(null);
    } catch (error) {
      console.error('Sign out error:', error);
      setError(error as Error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      error,
      signIn,
      signUp,
      signOut,
      refreshUser
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}