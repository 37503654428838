import { useState, useEffect } from 'react';
import { collection, query, getDocs, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import MealCard from '../components/meals/MealCard';
import MealFilters from '../components/meals/MealFilters';
import type { Meal } from '../types/database';

export default function MealsPage() {
  const [meals, setMeals] = useState<Meal[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    category: '',
    priceRange: '',
    availability: '',
  });

  useEffect(() => {
    const loadMeals = async () => {
      try {
        const mealsRef = collection(db, 'meals');
        const q = query(
          mealsRef,
          orderBy('created_at', 'desc'),
          limit(20)
        );
        const querySnapshot = await getDocs(q);
        const mealsData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as Meal[];
        setMeals(mealsData);
      } catch (error) {
        console.error('Error loading meals:', error);
      } finally {
        setLoading(false);
      }
    };

    loadMeals();
  }, [filters]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Plats disponibles</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        <aside className="lg:col-span-1">
          <MealFilters filters={filters} onChange={setFilters} />
        </aside>
        
        <div className="lg:col-span-3">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {meals.map((meal) => (
              <MealCard key={meal.meal_id} meal={meal} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}