import { useState, useEffect } from 'react';
import { Download, X } from 'lucide-react';
import Button from './Button';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export default function InstallPWA() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e as BeforeInstallPromptEvent);
      
      // Show the prompt after a delay
      setTimeout(() => {
        setShowPrompt(true);
      }, 3000);
    };

    window.addEventListener('beforeinstallprompt', handler);

    // Check if the app is already installed
    const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
    if (isInstalled) {
      setSupportsPWA(false);
    }

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const handleInstallClick = async () => {
    if (!promptInstall) return;

    try {
      await promptInstall.prompt();
      const { outcome } = await promptInstall.userChoice;
      
      if (outcome === 'accepted') {
        setSupportsPWA(false);
        setShowPrompt(false);
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    }
  };

  if (!supportsPWA || !showPrompt) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 md:left-auto md:right-4 md:w-96 bg-white rounded-lg shadow-lg p-4 z-50 border border-gray-200">
      <button
        onClick={() => setShowPrompt(false)}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <X className="w-5 h-5" />
      </button>

      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <img
            src="/logo.png"
            alt="BlablaFood"
            className="w-12 h-12 rounded-lg"
          />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-900">
            Installer BlablaFood
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            Installez notre application pour une meilleure expérience et un accès rapide à vos plats préférés.
          </p>
          <div className="flex space-x-3">
            <Button
              onClick={handleInstallClick}
              className="flex-1"
            >
              <Download className="w-4 h-4 mr-2" />
              Installer
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowPrompt(false)}
            >
              Plus tard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}