import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import Button from '../ui/Button';

export default function Hero() {
  const { home } = useTranslation();

  return (
    <div className="relative bg-gradient-to-br from-orange-50 to-orange-100 pt-32 pb-20">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            {home.hero.title}
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            {home.hero.subtitle}
          </p>
          <div className="flex flex-wrap gap-4">
            <Link to="/meals">
              <Button size="lg">
                {home.hero.exploreMeals}
                <ArrowRight className="ml-2 w-5 h-5" />
              </Button>
            </Link>
            <Link to="/auth">
              <Button variant="outline" size="lg">
                {home.hero.startCooking}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="absolute right-0 top-1/2 -translate-y-1/2 hidden lg:block w-1/3 h-full">
        <img
          src="https://images.unsplash.com/photo-1547592180-85f173990554?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          alt="Delicious homemade food"
          className="w-full h-full object-cover rounded-l-3xl shadow-2xl"
        />
      </div>
    </div>
  );
}