import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import { Clock, Package, Check, AlertCircle } from 'lucide-react';
import type { Order, OrderItem, Meal } from '../types/database';

interface OrderWithDetails extends Order {
  items: (OrderItem & { meal: Meal })[];
}

type OrderStatus = 'pending' | 'confirmed' | 'preparing' | 'ready' | 'delivered' | 'cancelled';

const ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
  pending: 'En attente de confirmation',
  confirmed: 'Confirmée',
  preparing: 'En préparation',
  ready: 'Prête pour le retrait',
  delivered: 'Récupérée',
  cancelled: 'Annulée'
};

const ORDER_STATUS_COLORS: Record<OrderStatus, { bg: string; text: string }> = {
  pending: { bg: 'bg-yellow-100', text: 'text-yellow-800' },
  confirmed: { bg: 'bg-blue-100', text: 'text-blue-800' },
  preparing: { bg: 'bg-orange-100', text: 'text-orange-800' },
  ready: { bg: 'bg-green-100', text: 'text-green-800' },
  delivered: { bg: 'bg-gray-100', text: 'text-gray-800' },
  cancelled: { bg: 'bg-red-100', text: 'text-red-800' }
};

export default function OrdersPage() {
  const { user } = useAuth();
  const [orders, setOrders] = useState<OrderWithDetails[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadOrders = async () => {
      if (!user) return;

      try {
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('consumer_id', '==', user.user_id),
          orderBy('created_at', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const ordersData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          order_id: doc.id,
        })) as OrderWithDetails[];

        setOrders(ordersData);
      } catch (error) {
        console.error('Error loading orders:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, [user]);

  const groupOrdersByStatus = (orders: OrderWithDetails[]) => {
    const activeOrders = orders.filter(order => 
      ['pending', 'confirmed', 'preparing', 'ready'].includes(order.order_status)
    );
    
    const completedOrders = orders.filter(order => 
      order.order_status === 'delivered'
    );
    
    const cancelledOrders = orders.filter(order => 
      order.order_status === 'cancelled'
    );

    return { activeOrders, completedOrders, cancelledOrders };
  };

  const { activeOrders, completedOrders, cancelledOrders } = groupOrdersByStatus(orders);

  const renderOrderCard = (order: OrderWithDetails) => (
    <div key={order.order_id} className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <p className="text-sm text-gray-500">Commande #{order.order_id.slice(-8)}</p>
            <p className="text-sm text-gray-500">
              <Clock className="w-4 h-4 inline mr-1" />
              {new Date(order.created_at).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </p>
          </div>
          <span className={`px-3 py-1 rounded-full text-sm font-medium ${
            ORDER_STATUS_COLORS[order.order_status as OrderStatus].bg
          } ${ORDER_STATUS_COLORS[order.order_status as OrderStatus].text}`}>
            {ORDER_STATUS_LABELS[order.order_status as OrderStatus]}
          </span>
        </div>

        <div className="border-t border-gray-200 pt-4">
          {order.items?.map((item) => (
            <div key={item.order_item_id} className="flex items-center gap-4 mb-4">
              <img
                src={item.meal.image_url || 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c'}
                alt={item.meal.name}
                className="w-16 h-16 object-cover rounded-lg"
              />
              <div>
                <p className="font-medium text-gray-900">{item.meal.name}</p>
                <p className="text-sm text-gray-600">
                  {item.quantity} x {item.price.toFixed(2)}€
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="border-t border-gray-200 pt-4 mt-4">
          <div className="flex justify-between text-gray-900">
            <span className="font-medium">Total</span>
            <span className="font-bold">{order.total_amount.toFixed(2)}€</span>
          </div>
          {order.order_status === 'ready' && (
            <div className="mt-4 p-4 bg-green-50 rounded-lg">
              <p className="text-green-800 flex items-center">
                <Check className="w-5 h-5 mr-2" />
                Votre commande est prête à être retirée !
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-600 mb-4">Vous n'avez pas encore passé de commande</p>
        <Button onClick={() => window.location.href = '/meals'}>Parcourir les plats</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Mes Commandes</h1>

      {activeOrders.length > 0 && (
        <div className="mb-12">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Commandes en cours
          </h2>
          <div className="space-y-6">
            {activeOrders.map(renderOrderCard)}
          </div>
        </div>
      )}

      {completedOrders.length > 0 && (
        <div className="mb-12">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Commandes récupérées
          </h2>
          <div className="space-y-6">
            {completedOrders.map(renderOrderCard)}
          </div>
        </div>
      )}

      {cancelledOrders.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Commandes annulées
          </h2>
          <div className="space-y-6">
            {cancelledOrders.map(renderOrderCard)}
          </div>
        </div>
      )}
    </div>
  );
}