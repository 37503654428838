import { ChefHat } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';

export default function Footer() {
  const { footer } = useTranslation();

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <Link to="/" className="flex items-center space-x-2">
              <ChefHat className="w-8 h-8 text-orange-500" />
              <span className="text-xl font-bold text-gray-900">BlablaFood</span>
            </Link>
            <p className="mt-4 text-gray-600 max-w-md">
              Connectez-vous avec des cuisiniers locaux et savourez d'authentiques plats faits maison. 
              Rejoignez notre communauté de passionnés de cuisine dès aujourd'hui.
            </p>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 mb-4">{footer.quickLinks.title}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/meals" className="text-gray-600 hover:text-orange-500">
                  {footer.quickLinks.browseMeals}
                </Link>
              </li>
              <li>
                <Link to="/auth" className="text-gray-600 hover:text-orange-500">
                  {footer.quickLinks.becomeCook}
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-600 hover:text-orange-500">
                  {footer.quickLinks.aboutUs}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 mb-4">{footer.support.title}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/faq" className="text-gray-600 hover:text-orange-500">
                  {footer.support.faq}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-orange-500">
                  {footer.support.contact}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-orange-500">
                  {footer.support.privacy}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-600">
            {footer.copyright.replace('{year}', new Date().getFullYear().toString())}
          </p>
          <p className="text-center text-gray-500 mt-2">
            <a href="mailto:info@emoting.fr" className="hover:text-orange-500">
              info@emoting.fr
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}