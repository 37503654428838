import { getApp, initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  connectFirestoreEmulator,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  persistentLocalCache,
  persistentMultipleTabManager,
  disableNetwork,
  enableNetwork,
  enableIndexedDbPersistence
} from 'firebase/firestore';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: "AIzaSyDW8G0MKHkPAbp-nKjefhwBWpIhbMBKeAk",
  authDomain: "blabla-food.firebaseapp.com",
  projectId: "blabla-food",
  storageBucket: "blabla-food.firebasestorage.app",
  messagingSenderId: "817853627733",
  appId: "1:817853627733:web:674665707742aad49183dc",
  measurementId: "G-7FSPFH21LN"
};

// Initialize Firebase with error handling
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error: any) {
  if (error.code === 'app/duplicate-app') {
    app = getApp();
  } else {
    console.error('Firebase initialization error:', error);
    throw error;
  }
}

// Initialize Firestore with enhanced offline persistence
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
});

// Enable offline persistence with error handling
try {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });
} catch (error) {
  console.warn('Error enabling persistence:', error);
}

// Initialize other services
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Set auth persistence to LOCAL
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting auth persistence:", error);
});

// Network status monitoring
let isOnline = navigator.onLine;
let retryCount = 0;
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;
let networkTimeout: NodeJS.Timeout | null = null;

const retryOperation = async (operation: () => Promise<any>) => {
  try {
    return await operation();
  } catch (error: any) {
    if (error.code === 'unavailable' && retryCount < MAX_RETRIES) {
      retryCount++;
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * retryCount));
      return retryOperation(operation);
    }
    throw error;
  }
};

// Network status handlers
const handleOffline = async () => {
  if (isOnline) {
    isOnline = false;
    try {
      await disableNetwork(db);
      toast.error('Mode hors ligne activé. Les données seront synchronisées une fois la connexion rétablie.');
    } catch (error) {
      console.error('Error handling offline state:', error);
    }
  }
};

const handleOnline = async () => {
  if (!isOnline) {
    try {
      await enableNetwork(db);
      isOnline = true;
      retryCount = 0;
      toast.success('Connexion rétablie. Synchronisation des données...');
    } catch (error) {
      console.error('Error handling online state:', error);
    }
  }
};

// Network event listeners with debouncing
if (typeof window !== 'undefined') {
  window.addEventListener('online', () => {
    if (networkTimeout) clearTimeout(networkTimeout);
    networkTimeout = setTimeout(handleOnline, 1000);
  });

  window.addEventListener('offline', () => {
    if (networkTimeout) clearTimeout(networkTimeout);
    networkTimeout = setTimeout(handleOffline, 1000);
  });

  // Initial connection check
  if (!navigator.onLine) {
    handleOffline();
  }
}

export { app, db, auth, storage, functions, retryOperation };

export const collections = {
  users: 'users',
  cookProfiles: 'cookProfiles',
  meals: 'meals',
  orders: 'orders',
  orderItems: 'orderItems',
  reviews: 'reviews',
  transactions: 'transactions',
  subscriptions: 'subscriptions',
  advertisements: 'advertisements',
  carts: 'carts',
  cartItems: 'cartItems'
} as const;

export const isNetworkOnline = () => isOnline;