import { useEffect } from 'react';

export default function PrivacyPage() {
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-sm p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Politique de Confidentialité - BlablaFood.fr</h1>
          <p className="text-gray-600 mb-4">Date d'entrée en vigueur : 1 mars 2024</p>

          <div className="prose prose-orange max-w-none">
            <p>
              BlablaFood.fr (« nous », « notre » ou « BlablaFood ») s'engage à protéger la vie privée de ses utilisateurs. 
              La présente politique de confidentialité décrit comment nous recueillons, utilisons, partageons et protégeons 
              les informations personnelles de nos utilisateurs lorsqu'ils accèdent à notre plateforme de partage culinaire 
              de voisinage. En utilisant notre site et nos services, vous acceptez les pratiques décrites dans cette politique.
            </p>

            <h2>1. Informations Collectées</h2>
            <h3>1.1 Informations fournies par les utilisateurs</h3>
            <p>Lors de votre inscription et de l'utilisation de notre service, nous pouvons collecter des informations personnelles, notamment :</p>
            <ul>
              <li>Nom et prénom</li>
              <li>Adresse e-mail</li>
              <li>Numéro de téléphone</li>
              <li>Adresse postale et localisation géographique</li>
              <li>Informations de paiement (via un prestataire de services de paiement sécurisé)</li>
              <li>Informations de profil (spécialités culinaires, préférences alimentaires, disponibilités, etc.)</li>
            </ul>

            <h3>1.2 Informations collectées automatiquement</h3>
            <p>Lorsque vous visitez notre site, certaines informations peuvent être collectées automatiquement :</p>
            <ul>
              <li>Données de navigation (adresse IP, type de navigateur, système d'exploitation)</li>
              <li>Cookies et technologies similaires pour suivre vos préférences et améliorer votre expérience utilisateur</li>
              <li>Informations d'interaction (pages visitées, durée de la visite, actions sur le site)</li>
            </ul>

            <h2>2. Utilisation des Informations Collectées</h2>
            <p>Nous utilisons vos informations pour :</p>
            <ul>
              <li>Fournir, exploiter et améliorer nos services</li>
              <li>Faciliter les interactions et transactions entre utilisateurs</li>
              <li>Personnaliser l'expérience utilisateur</li>
              <li>Communiquer avec vous pour le support client</li>
              <li>Protéger la sécurité de notre site et de nos utilisateurs</li>
              <li>Respecter les obligations légales et réglementaires</li>
            </ul>

            <h2>3. Partage des Informations</h2>
            <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
            <ul>
              <li>Prestataires de services nécessaires au fonctionnement de la plateforme</li>
              <li>Autres utilisateurs dans le cadre de l'expérience BlablaFood</li>
              <li>Conformité légale</li>
              <li>Fusion ou acquisition potentielle</li>
            </ul>

            <h2>4. Vos Droits et Choix</h2>
            <p>Vous disposez des droits suivants concernant vos données personnelles :</p>
            <ul>
              <li>Droit d'accès à vos données</li>
              <li>Droit de rectification</li>
              <li>Droit à l'effacement</li>
              <li>Droit d'opposition au traitement</li>
              <li>Droit à la portabilité des données</li>
            </ul>

            <h2>5. Sécurité des Données</h2>
            <p>
              Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations. 
              Cependant, aucun système n'est totalement sécurisé, et nous ne pouvons garantir la sécurité 
              absolue de vos données.
            </p>

            <h2>6. Cookies et Technologies Similaires</h2>
            <p>
              Nous utilisons des cookies pour améliorer votre expérience sur notre site. 
              Vous pouvez gérer vos préférences de cookies dans les paramètres de votre navigateur.
            </p>

            <h2>7. Modifications de la Politique</h2>
            <p>
              Nous nous réservons le droit de modifier cette politique à tout moment. 
              Les modifications seront publiées sur cette page avec la date de mise à jour.
            </p>

            <h2>8. Nous Contacter</h2>
            <p>Pour toute question concernant cette politique de confidentialité, contactez-nous :</p>
            <p>
              Email : <a href="mailto:info@emoting.fr" className="text-orange-500 hover:text-orange-600">info@emoting.fr</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}