import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Stripe with test publishable key
export const stripePromise = loadStripe('pk_test_51OqwXqK8bPfHF9V4X0RpWZ9N0XzKlCm6N2jY3gQ4Z1K2X3Y4W5V6U7T8N9M0P', {
  locale: 'fr',
  apiVersion: '2023-10-16'
});

export async function createPaymentIntent(amount: number) {
  try {
    const functions = getFunctions();
    const createPaymentIntentFunction = httpsCallable(functions, 'createPaymentIntent');
    
    // Amount must be in cents
    const result = await createPaymentIntentFunction({ 
      amount: Math.round(amount * 100),
      currency: 'eur'
    });
    
    return result.data as { clientSecret: string };
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
}

export async function createCustomerPortal(returnUrl?: string) {
  try {
    const functions = getFunctions();
    const createPortalFunction = httpsCallable(functions, 'createCustomerPortal');
    
    const result = await createPortalFunction({ 
      returnUrl: returnUrl || window.location.origin + '/profile'
    });
    
    return result.data as { url: string };
  } catch (error) {
    console.error('Error creating customer portal:', error);
    throw error;
  }
}