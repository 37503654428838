import { Link } from 'react-router-dom';
import { Clock, Star, ChefHat, ShoppingBag, Plus, Minus } from 'lucide-react';
import { useState, useEffect } from 'react';
import { getUser } from '../../lib/database';
import { useCart } from '../../contexts/CartContext';
import Button from '../ui/Button';
import Confetti from '../ui/Confetti';
import type { Meal, User } from '../../types/database';
import toast from 'react-hot-toast';

interface MealCardProps {
  meal: Meal;
}

export default function MealCard({ meal }: MealCardProps) {
  const [cook, setCook] = useState<User | null>(null);
  const { addItem, getItemQuantity, updateQuantity, cartItems } = useCart();
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const cartItem = cartItems.find(item => item.meal_id === meal.meal_id);
  const currentQuantity = getItemQuantity(meal.meal_id);
  const availableStock = meal.portions_available - currentQuantity;

  useEffect(() => {
    const loadCook = async () => {
      try {
        const cookData = await getUser(meal.cook_id);
        setCook(cookData);
      } catch (error) {
        console.error('Error loading cook:', error);
      }
    };

    loadCook();
  }, [meal.cook_id]);

  const handleAddToCart = async () => {
    if (availableStock < 1) {
      toast.error('Stock insuffisant');
      return;
    }

    setLoading(true);
    try {
      await addItem(meal.meal_id, 1);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    } catch (error) {
      console.error('Error adding to cart:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateQuantity = async (newQuantity: number) => {
    if (!cartItem) return;
    
    setLoading(true);
    try {
      await updateQuantity(cartItem.cart_item_id, newQuantity);
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showConfetti && <Confetti />}
      <Link to={`/meals/${meal.meal_id}`} className="group">
        <div className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow">
          <div className="relative aspect-[4/3]">
            <img
              src={meal.image_url || 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c'}
              alt={meal.name}
              className="w-full h-full object-cover transition-transform group-hover:scale-105"
            />
            {availableStock < 1 && (
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                <span className="text-white font-semibold text-lg">Épuisé</span>
              </div>
            )}
            {availableStock > 0 && availableStock <= 3 && (
              <div className="absolute top-2 right-2 px-3 py-1 bg-red-500 text-white text-sm font-medium rounded-full">
                Plus que {availableStock} disponible{availableStock > 1 ? 's' : ''}
              </div>
            )}
          </div>
          
          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold text-gray-900 group-hover:text-orange-500">
                {meal.name}
              </h3>
              <span className="font-bold text-orange-500">
                {meal.price.toFixed(2)}€
              </span>
            </div>
            
            <div className="flex items-center text-sm text-gray-600 mb-2">
              <ChefHat className="w-4 h-4 mr-1" />
              <span>par {cook?.username || '...'}</span>
            </div>
            
            <p className="text-sm text-gray-600 line-clamp-2 mb-4">
              {meal.description}
            </p>
            
            <div className="flex items-center justify-between text-sm text-gray-500">
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                <span>{meal.preparation_time} mins</span>
              </div>
              {meal.rating_average && (
                <div className="flex items-center">
                  <Star className="w-4 h-4 mr-1 text-yellow-400 fill-current" />
                  <span>{meal.rating_average.toFixed(1)}</span>
                </div>
              )}
            </div>

            {currentQuantity > 0 ? (
              <div className="mt-4 flex items-center justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleUpdateQuantity(currentQuantity - 1);
                  }}
                  className="p-2 text-gray-600 hover:text-orange-500"
                  disabled={loading}
                >
                  <Minus className="w-4 h-4" />
                </button>
                <span className="font-medium">{currentQuantity}</span>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleUpdateQuantity(currentQuantity + 1);
                  }}
                  className="p-2 text-gray-600 hover:text-orange-500"
                  disabled={loading || availableStock < 1}
                >
                  <Plus className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleAddToCart();
                }}
                className="w-full mt-4"
                disabled={loading || availableStock < 1}
                size="sm"
              >
                <ShoppingBag className="w-4 h-4 mr-2" />
                Ajouter au panier
              </Button>
            )}
          </div>
        </div>
      </Link>
    </>
  );
}