import { ShoppingBag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Button from '../ui/Button';

export default function EmptyCart() {
  const navigate = useNavigate();

  return (
    <div className="text-center py-12">
      <ShoppingBag className="w-16 h-16 text-gray-400 mx-auto mb-4" />
      <p className="text-gray-600 mb-4">Votre panier est vide</p>
      <Button onClick={() => navigate('/meals')}>Parcourir les plats</Button>
    </div>
  );
}