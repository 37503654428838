import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { ShoppingBag } from 'lucide-react';
import Button from '../components/ui/Button';
import CartItem from '../components/cart/CartItem';
import CartSummary from '../components/cart/CartSummary';
import EmptyCart from '../components/cart/EmptyCart';
import Confetti from '../components/ui/Confetti';
import toast from 'react-hot-toast';

export default function CartPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { cartItems, removeItem, updateQuantity } = useCart();
  const [processingCheckout, setProcessingCheckout] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const subtotal = cartItems.reduce((total, item) => total + (item.meal.price * item.quantity), 0);

  const handleQuantityChange = async (cartItemId: string, newQuantity: number) => {
    if (newQuantity < 1) {
      await removeItem(cartItemId);
      return;
    }
    await updateQuantity(cartItemId, newQuantity);
  };

  const handleProceedToCheckout = async () => {
    if (!user) {
      toast.error('Veuillez vous connecter pour continuer');
      navigate('/auth');
      return;
    }

    if (cartItems.length === 0) {
      toast.error('Votre panier est vide');
      return;
    }

    // Vérifier les stocks disponibles
    for (const item of cartItems) {
      if (item.quantity > item.meal.portions_available) {
        toast.error(`Stock insuffisant pour ${item.meal.name}`);
        return;
      }
    }

    try {
      setProcessingCheckout(true);

      const checkoutData = {
        cartItems,
        subtotal,
        total: subtotal
      };

      sessionStorage.setItem('checkout_data', JSON.stringify(checkoutData));
      setShowConfetti(true);
      
      setTimeout(() => {
        navigate('/checkout');
      }, 1000);
    } catch (error) {
      console.error('Error proceeding to checkout:', error);
      toast.error('Erreur lors du passage à la caisse');
    } finally {
      setProcessingCheckout(false);
    }
  };

  if (!user) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <ShoppingBag className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Connectez-vous pour voir votre panier
        </h2>
        <Button onClick={() => navigate('/auth')}>Se connecter</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      {showConfetti && <Confetti />}
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Votre Panier</h1>

      {cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="space-y-4">
              {cartItems.map((item) => (
                <CartItem
                  key={item.cart_item_id}
                  item={item}
                  onQuantityChange={handleQuantityChange}
                  onRemove={removeItem}
                />
              ))}
            </div>
          </div>

          <div className="lg:col-span-1">
            <CartSummary
              subtotal={subtotal}
              onCheckout={handleProceedToCheckout}
              isProcessing={processingCheckout}
            />
          </div>
        </div>
      )}
    </div>
  );
}