import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Star, ChefHat } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import type { User, CookProfile } from '../../types/database';

interface CookWithProfile extends User {
  profile: CookProfile;
}

export default function PopularCooks() {
  const { home } = useTranslation();
  const [cooks, setCooks] = useState<CookWithProfile[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPopularCooks = async () => {
      try {
        // Charger les profils de cuisiniers
        const cookProfilesRef = collection(db, 'cookProfiles');
        const q = query(
          cookProfilesRef,
          orderBy('rating_average', 'desc'),
          limit(3)
        );
        const profilesSnap = await getDocs(q);
        
        // Charger les informations utilisateur correspondantes
        const cooksData = await Promise.all(
          profilesSnap.docs.map(async (doc) => {
            const profile = { ...doc.data(), cook_id: doc.id } as CookProfile;
            const userRef = collection(db, 'users');
            const userQ = query(userRef, where('user_id', '==', profile.user_id));
            const userSnap = await getDocs(userQ);
            const userData = userSnap.docs[0].data() as User;
            
            return {
              ...userData,
              profile
            };
          })
        );

        setCooks(cooksData);
      } catch (error) {
        console.error('Error loading popular cooks:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPopularCooks();
  }, []);

  if (loading) {
    return (
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-gray-200 rounded w-1/4 mx-auto"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3].map((i) => (
                <div key={i} className="bg-gray-100 rounded-xl h-96"></div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (cooks.length === 0) {
    return null;
  }

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">{home.popularCooks.title}</h2>
          <p className="mt-4 text-lg text-gray-600">
            {home.popularCooks.subtitle}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cooks.map((cook) => (
            <Link 
              key={cook.user_id} 
              to={`/cook/${cook.user_id}`}
              className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="p-6">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="bg-orange-100 rounded-full p-3">
                    <ChefHat className="w-6 h-6 text-orange-500" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {cook.username}
                    </h3>
                    <p className="text-gray-600">{cook.location}</p>
                  </div>
                </div>
                
                <div className="flex items-center mb-4">
                  <Star className="w-5 h-5 text-yellow-400 fill-current" />
                  <span className="ml-2 font-medium">
                    {cook.profile.rating_average?.toFixed(1) || '0.0'}
                  </span>
                  <span className="ml-2 text-gray-500">
                    ({cook.profile.total_reviews || 0} avis)
                  </span>
                </div>
                
                {cook.profile.specialties && cook.profile.specialties.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {cook.profile.specialties.map((specialty, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-orange-100 text-orange-600 rounded-full text-sm"
                      >
                        {specialty}
                      </span>
                    ))}
                  </div>
                )}

                {cook.profile.bio && (
                  <p className="mt-4 text-gray-600 text-sm line-clamp-2">
                    {cook.profile.bio}
                  </p>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}