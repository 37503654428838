interface MealFiltersProps {
  filters: {
    category: string;
    priceRange: string;
    availability: string;
  };
  onChange: (filters: any) => void;
}

export default function MealFilters({ filters, onChange }: MealFiltersProps) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChange({ ...filters, [name]: value });
  };

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-2">
          Catégorie
        </label>
        <select
          id="category"
          name="category"
          value={filters.category}
          onChange={handleChange}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        >
          <option value="">Toutes les catégories</option>
          <option value="italian">Italien</option>
          <option value="asian">Asiatique</option>
          <option value="mexican">Mexicain</option>
          <option value="indian">Indien</option>
          <option value="french">Français</option>
          <option value="mediterranean">Méditerranéen</option>
          <option value="japanese">Japonais</option>
          <option value="thai">Thaïlandais</option>
          <option value="lebanese">Libanais</option>
          <option value="vegan">Végétalien</option>
          <option value="vegetarian">Végétarien</option>
          <option value="desserts">Desserts</option>
          <option value="healthy">Healthy</option>
        </select>
      </div>

      <div>
        <label htmlFor="priceRange" className="block text-sm font-medium text-gray-700 mb-2">
          Fourchette de prix
        </label>
        <select
          id="priceRange"
          name="priceRange"
          value={filters.priceRange}
          onChange={handleChange}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        >
          <option value="">Tous les prix</option>
          <option value="under10">Moins de 10€</option>
          <option value="10-20">10€ - 20€</option>
          <option value="20-30">20€ - 30€</option>
          <option value="over30">Plus de 30€</option>
        </select>
      </div>

      <div>
        <label htmlFor="availability" className="block text-sm font-medium text-gray-700 mb-2">
          Disponibilité
        </label>
        <select
          id="availability"
          name="availability"
          value={filters.availability}
          onChange={handleChange}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        >
          <option value="">Toutes les disponibilités</option>
          <option value="available">Disponible maintenant</option>
          <option value="today">Disponible aujourd'hui</option>
          <option value="tomorrow">Disponible demain</option>
        </select>
      </div>
    </div>
  );
}