import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChefHat, LogIn, Menu, X, Search, ShoppingBag } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useCart } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';
import Button from '../ui/Button';

export default function Header() {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { cartItems } = useCart();
  const { header } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
      <nav className="container mx-auto px-4">
        <div className="h-16 flex items-center justify-between">
          <div className="flex items-center space-x-8">
            <Link to="/" className="flex items-center space-x-2">
              <ChefHat className="w-8 h-8 text-[#4285f4]" />
              <span className="text-xl font-bold text-[#4285f4]">BlablaFood</span>
            </Link>

            <div className="relative hidden md:block">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="search"
                placeholder="Rechercher des plats..."
                className="pl-10 pr-4 py-2 w-64 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4285f4] focus:border-transparent"
              />
            </div>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <Link 
              to="/meals" 
              className="px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-full transition-colors"
            >
              Parcourir les plats
            </Link>

            {user ? (
              <>
                {user.user_type === 'cook' && (
                  <Link to="/cook/dashboard">
                    <Button variant="outline" size="sm">
                      <ChefHat className="w-4 h-4 mr-2" />
                      Tableau de bord
                    </Button>
                  </Link>
                )}

                <Link 
                  to="/orders" 
                  className="px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-full transition-colors"
                >
                  Mes commandes
                </Link>

                <Link 
                  to="/cart" 
                  className="px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-full transition-colors flex items-center"
                >
                  <ShoppingBag className="w-4 h-4 mr-2" />
                  Panier ({cartItems.length})
                </Link>

                <div className="flex items-center space-x-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => navigate('/profile')}
                  >
                    Profil
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={handleSignOut}
                  >
                    Déconnexion
                  </Button>
                </div>
              </>
            ) : (
              <Link to="/auth">
                <Button size="sm">
                  <LogIn className="w-4 h-4 mr-2" />
                  Connexion
                </Button>
              </Link>
            )}
          </div>

          <button 
            onClick={toggleMenu}
            className="md:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-gray-600" />
            ) : (
              <Menu className="w-6 h-6 text-gray-600" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-200 shadow-lg">
            <div className="px-4 py-2">
              <div className="relative mb-4">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="search"
                  placeholder="Rechercher des plats..."
                  className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4285f4] focus:border-transparent"
                />
              </div>

              <div className="space-y-2">
                <Link 
                  to="/meals" 
                  className="block px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Parcourir les plats
                </Link>

                {user ? (
                  <>
                    {user.user_type === 'cook' && (
                      <Link 
                        to="/cook/dashboard"
                        className="block px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <ChefHat className="w-4 h-4 inline mr-2" />
                        Tableau de bord
                      </Link>
                    )}

                    <Link 
                      to="/orders"
                      className="block px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Mes commandes
                    </Link>

                    <Link 
                      to="/cart"
                      className="block px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <ShoppingBag className="w-4 h-4 inline mr-2" />
                      Panier ({cartItems.length})
                    </Link>

                    <button
                      onClick={() => {
                        navigate('/profile');
                        setIsMenuOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                    >
                      Profil
                    </button>

                    <button
                      onClick={() => {
                        handleSignOut();
                        setIsMenuOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                    >
                      Déconnexion
                    </button>
                  </>
                ) : (
                  <Link 
                    to="/auth"
                    className="block px-4 py-2 text-sm font-medium text-[#4285f4] hover:bg-blue-50 rounded-lg transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <LogIn className="w-4 h-4 inline mr-2" />
                    Connexion
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}