import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { getUser } from '../lib/database';
import Button from '../components/ui/Button';
import { Clock, Users, ChefHat, AlertCircle } from 'lucide-react';
import type { Meal, User } from '../types/database';
import toast from 'react-hot-toast';

export default function MealDetailsPage() {
  const { mealId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addItem, cartItems } = useCart();
  const [meal, setMeal] = useState<Meal | null>(null);
  const [cook, setCook] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [addingToCart, setAddingToCart] = useState(false);

  // Calculer la quantité déjà dans le panier pour ce plat
  const currentCartQuantity = cartItems.find(item => item.meal_id === mealId)?.quantity || 0;
  const availableStock = meal ? meal.portions_available - currentCartQuantity : 0;

  useEffect(() => {
    const loadMealAndCook = async () => {
      if (!mealId) return;
      
      try {
        const mealRef = doc(db, 'meals', mealId);
        const mealSnap = await getDoc(mealRef);
        
        if (mealSnap.exists()) {
          const mealData = { ...mealSnap.data(), meal_id: mealSnap.id } as Meal;
          setMeal(mealData);

          // Charger les informations du cuisinier
          const cookData = await getUser(mealData.cook_id);
          setCook(cookData);
        }
      } catch (error) {
        console.error('Error loading meal:', error);
        toast.error('Failed to load meal details');
      } finally {
        setLoading(false);
      }
    };

    loadMealAndCook();
  }, [mealId]);

  const handleAddToCart = async () => {
    if (!meal || !user) {
      toast.error('Please sign in to add items to cart');
      navigate('/auth');
      return;
    }

    // Vérifier si la quantité demandée est disponible
    if (quantity > availableStock) {
      toast.error(`Stock insuffisant. Il ne reste que ${availableStock} portion${availableStock > 1 ? 's' : ''} disponible${availableStock > 1 ? 's' : ''}`);
      return;
    }

    try {
      setAddingToCart(true);
      await addItem(meal.meal_id, quantity);
      toast.success(`${quantity} portion${quantity > 1 ? 's' : ''} ajoutée${quantity > 1 ? 's' : ''} au panier`);
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to cart');
    } finally {
      setAddingToCart(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!meal) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Meal not found</h1>
        <Button onClick={() => navigate('/meals')}>Browse Other Meals</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="relative aspect-square rounded-2xl overflow-hidden">
          <img
            src={meal.image_url || 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c'}
            alt={meal.name}
            className="w-full h-full object-cover"
          />
        </div>

        <div className="space-y-6">
          <h1 className="text-4xl font-bold text-gray-900">{meal.name}</h1>
          
          <div className="flex items-center space-x-6 text-gray-600">
            <div className="flex items-center">
              <Clock className="w-5 h-5 mr-2" />
              <span>{meal.preparation_time} mins</span>
            </div>
            <div className="flex items-center">
              <Users className="w-5 h-5 mr-2" />
              <span>{availableStock} portion{availableStock !== 1 ? 's' : ''} disponible{availableStock !== 1 ? 's' : ''}</span>
            </div>
            <div className="flex items-center">
              <ChefHat className="w-5 h-5 mr-2" />
              <span>par {cook?.username || '...'}</span>
            </div>
          </div>

          <p className="text-gray-600">{meal.description}</p>

          <div className="border-t border-b border-gray-200 py-6">
            <h3 className="text-lg font-semibold mb-4">Ingredients</h3>
            <ul className="list-disc list-inside space-y-2">
              {meal.ingredients.map((ingredient, index) => (
                <li key={index} className="text-gray-600">{ingredient}</li>
              ))}
            </ul>
          </div>

          {meal.allergens && meal.allergens.length > 0 && (
            <div className="flex items-start space-x-2 text-amber-600">
              <AlertCircle className="w-5 h-5 mt-0.5" />
              <div>
                <p className="font-semibold">Allergens</p>
                <p className="text-sm">{meal.allergens.join(', ')}</p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="text-2xl font-bold text-gray-900">
                {meal.price.toFixed(2)}€
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setQuantity(q => Math.max(1, q - 1))}
                  className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300"
                  disabled={quantity <= 1}
                >
                  -
                </button>
                <span className="text-lg font-medium">{quantity}</span>
                <button
                  onClick={() => setQuantity(q => Math.min(availableStock, q + 1))}
                  className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300"
                  disabled={quantity >= availableStock}
                >
                  +
                </button>
              </div>
            </div>

            {currentCartQuantity > 0 && (
              <p className="text-sm text-gray-500">
                Vous avez déjà {currentCartQuantity} portion{currentCartQuantity > 1 ? 's' : ''} dans votre panier
              </p>
            )}

            <Button
              onClick={handleAddToCart}
              className="w-full"
              isLoading={addingToCart}
              disabled={availableStock < 1}
            >
              {availableStock < 1 ? 'Épuisé' : `Ajouter au panier (${quantity})`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}