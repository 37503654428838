import { useState } from 'react';
import { Award, Book, Medal, Clock, Flame, Star, Utensils } from 'lucide-react';
import Button from '../ui/Button';
import { User } from '../../types/database';

interface ChefProfileSectionProps {
  user: User;
  onUpdate: (data: Partial<User>) => Promise<void>;
}

export default function ChefProfileSection({ user, onUpdate }: ChefProfileSectionProps) {
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    specialties: user.specialties || [],
    cookingStyles: user.cooking_styles || [],
    experienceYears: user.experience_years || 0,
    certifications: user.certifications || [],
    signatureDishes: user.signature_dishes || [],
    cuisineTypes: user.cuisine_types || [],
    awards: user.awards || [],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onUpdate(formData);
    setEditing(false);
  };

  const handleArrayInput = (field: keyof typeof formData, value: string) => {
    const items = value.split(',').map(item => item.trim()).filter(Boolean);
    setFormData(prev => ({ ...prev, [field]: items }));
  };

  if (!editing) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
        <div className="flex justify-between items-start">
          <h2 className="text-xl font-semibold text-gray-900">Profil Cuisinier</h2>
          <Button variant="outline" size="sm" onClick={() => setEditing(true)}>
            Modifier
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Utensils className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Spécialités</h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.specialties.map((specialty, index) => (
                  <span key={index} className="px-3 py-1 bg-blue-50 text-[#4285f4] rounded-full text-sm">
                    {specialty}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Flame className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Styles de Cuisine</h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.cookingStyles.map((style, index) => (
                  <span key={index} className="px-3 py-1 bg-blue-50 text-[#4285f4] rounded-full text-sm">
                    {style}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Clock className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Expérience</h3>
              </div>
              <p className="text-gray-600">
                {formData.experienceYears} ans d'expérience
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Medal className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Certifications</h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.certifications.map((cert, index) => (
                  <span key={index} className="px-3 py-1 bg-green-50 text-green-700 rounded-full text-sm">
                    {cert}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Book className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Plats Signature</h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.signatureDishes.map((dish, index) => (
                  <span key={index} className="px-3 py-1 bg-orange-50 text-orange-700 rounded-full text-sm">
                    {dish}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center text-[#4285f4] mb-2">
                <Award className="w-5 h-5 mr-2" />
                <h3 className="font-medium">Récompenses</h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.awards.map((award, index) => (
                  <span key={index} className="px-3 py-1 bg-purple-50 text-purple-700 rounded-full text-sm">
                    {award}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-sm p-6 space-y-6">
      <h2 className="text-xl font-semibold text-gray-900">Modifier le Profil Cuisinier</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Spécialités (séparées par des virgules)
          </label>
          <input
            type="text"
            value={formData.specialties.join(', ')}
            onChange={(e) => handleArrayInput('specialties', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Styles de Cuisine (séparés par des virgules)
          </label>
          <input
            type="text"
            value={formData.cookingStyles.join(', ')}
            onChange={(e) => handleArrayInput('cookingStyles', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Années d'Expérience
          </label>
          <input
            type="number"
            value={formData.experienceYears}
            onChange={(e) => setFormData(prev => ({ ...prev, experienceYears: parseInt(e.target.value) || 0 }))}
            min="0"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Certifications (séparées par des virgules)
          </label>
          <input
            type="text"
            value={formData.certifications.join(', ')}
            onChange={(e) => handleArrayInput('certifications', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Plats Signature (séparés par des virgules)
          </label>
          <input
            type="text"
            value={formData.signatureDishes.join(', ')}
            onChange={(e) => handleArrayInput('signatureDishes', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Récompenses (séparées par des virgules)
          </label>
          <input
            type="text"
            value={formData.awards.join(', ')}
            onChange={(e) => handleArrayInput('awards', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
          />
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="outline"
          onClick={() => setEditing(false)}
        >
          Annuler
        </Button>
        <Button type="submit">
          Enregistrer
        </Button>
      </div>
    </form>
  );
}