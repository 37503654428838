import { createContext, useContext } from 'react';
import { fr } from '../lib/translations';

const TranslationContext = createContext(fr);

export function TranslationProvider({ children }: { children: React.ReactNode }) {
  return (
    <TranslationContext.Provider value={fr}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  return useContext(TranslationContext);
}