import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FaqItem {
  question: string;
  answer: string | string[];
}

interface FaqSection {
  title: string;
  items: FaqItem[];
}

const faqData: FaqSection[] = [
  {
    title: "Sécurité et Hygiène",
    items: [
      {
        question: "Comment BlablaFood garantit-il l'hygiène des repas ?",
        answer: "BlablaFood accorde une grande importance à l'hygiène et à la sécurité alimentaire. Tous les cuisiniers s'engagent à respecter les normes d'hygiène alimentaire strictes et sont encouragés à suivre des formations sur les bonnes pratiques en cuisine. De plus, BlablaFood effectue des vérifications régulières et fournit des ressources pédagogiques pour garantir une manipulation et une préparation sécurisées des aliments."
      },
      {
        question: "Les cuisiniers sont-ils formés en sécurité alimentaire ?",
        answer: "Oui, nous encourageons fortement tous les cuisiniers de la plateforme à suivre une formation en hygiène alimentaire. Certains utilisateurs sont également certifiés, ce qui est indiqué sur leur profil pour une plus grande transparence. Nous fournissons également des recommandations et des guides pour aider chaque cuisinier à respecter les standards de sécurité alimentaire."
      },
      {
        question: "Comment puis-je savoir si un cuisinier respecte les normes d'hygiène ?",
        answer: "Nous avons mis en place un système d'évaluation qui permet aux consommateurs de noter et de laisser des commentaires après chaque commande. Cela permet de créer une communauté de confiance et d'assurer une transparence totale sur la qualité des repas. De plus, les profils des cuisiniers comportent des informations sur leur engagement en matière d'hygiène et de sécurité."
      }
    ]
  },
  {
    title: "Qualité et Service",
    items: [
      {
        question: "Que se passe-t-il en cas de problème avec un repas ?",
        answer: "Si vous rencontrez un problème de qualité ou d'hygiène avec un repas, vous pouvez contacter notre service client, disponible 24/7, ou laisser un commentaire sur le profil du cuisinier. Nous prenons ces retours très au sérieux et travaillons activement pour résoudre toute situation problématique. Dans certains cas, nous pouvons également prendre des mesures supplémentaires, telles qu'une vérification approfondie du profil concerné."
      },
      {
        question: "Les ingrédients utilisés sont-ils de bonne qualité ?",
        answer: "BlablaFood encourage ses cuisiniers à utiliser des ingrédients frais et de bonne qualité. De plus, certains cuisiniers privilégient les produits locaux, bio ou de saison, et indiquent cette information sur leur profil. Vous pouvez filtrer les offres en fonction de vos préférences alimentaires pour trouver des repas adaptés à vos besoins."
      },
      {
        question: "Comment sont gérés les risques d'allergie ?",
        answer: "Les cuisiniers de BlablaFood sont tenus de détailler les ingrédients utilisés dans chaque plat, et nous encourageons une transparence totale en matière d'allergènes. Vous pouvez consulter la liste des ingrédients sur l'offre du plat et contacter le cuisinier pour toute question spécifique. Cependant, si vous avez une allergie sévère, nous recommandons toujours de confirmer avec le cuisinier avant de passer commande."
      }
    ]
  },
  {
    title: "Fonctionnement",
    items: [
      {
        question: "Est-ce que BlablaFood est en concurrence avec les restaurants ?",
        answer: "BlablaFood ne vise pas à concurrencer les restaurants. La plateforme propose des repas faits maison, souvent à des prix abordables et dans une optique de convivialité de voisinage. Il s'agit d'une alternative aux plats industriels ou aux services de livraison traditionnels, offrant une option pour ceux qui recherchent une cuisine authentique, locale et conviviale."
      },
      {
        question: "Quelles mesures sont mises en place pour la sécurité des transactions ?",
        answer: "Toutes les transactions sur BlablaFood sont sécurisées grâce à notre prestataire de paiement certifié. Les informations bancaires ne sont jamais stockées sur nos serveurs et sont protégées par des systèmes de cryptage pour garantir la sécurité de chaque paiement."
      },
      {
        question: "Puis-je obtenir un remboursement si je ne suis pas satisfait ?",
        answer: "Oui, dans le cas où le repas ne répondrait pas aux attentes décrites ou en cas de problème d'hygiène, nous vous encourageons à nous contacter rapidement. Nous évaluerons la situation et, si nécessaire, nous pourrons vous proposer un remboursement ou un bon d'achat pour votre prochaine commande."
      }
    ]
  },
  {
    title: "Cuisiniers et Évaluations",
    items: [
      {
        question: "Les cuisiniers sont-ils des professionnels ?",
        answer: "La majorité de nos cuisiniers sont des amateurs passionnés ou des semi-professionnels, ce qui rend les repas faits maison uniques et authentiques. Nous vérifions les profils des cuisiniers et mettons en place des processus d'évaluation pour garantir une qualité fiable, tout en encourageant une démarche de partage et de respect des normes d'hygiène."
      },
      {
        question: "Comment puis-je évaluer un cuisinier ?",
        answer: "Après chaque commande, vous avez la possibilité de laisser une note et un commentaire sur la qualité du repas et l'expérience globale. Ces avis sont importants pour aider les futurs utilisateurs à choisir et pour maintenir un haut niveau de qualité sur la plateforme."
      },
      {
        question: "Quelles précautions dois-je prendre en tant que consommateur ?",
        answer: [
          "Vérifier les avis et évaluations des cuisiniers",
          "Consulter les informations sur les ingrédients, notamment pour les allergies",
          "Contacter le cuisinier si vous avez des questions spécifiques avant de commander"
        ]
      }
    ]
  }
];

export default function FaqPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  const toggleItem = (questionId: string) => {
    setOpenItems(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-4">
            FAQ - BlablaFood
          </h1>
          <p className="text-gray-600 text-center mb-12">
            Trouvez les réponses à vos questions sur notre service de repas entre voisins
          </p>

          {/* Tabs Navigation */}
          <div className="flex flex-wrap gap-2 mb-8">
            {faqData.map((section, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  activeTab === index
                    ? 'bg-orange-500 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                {section.title}
              </button>
            ))}
          </div>

          {/* FAQ Content */}
          <div className="bg-white rounded-2xl shadow-sm">
            {faqData[activeTab].items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-200 last:border-0"
              >
                <button
                  onClick={() => toggleItem(`${activeTab}-${index}`)}
                  className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
                >
                  <span className="font-medium text-gray-900">{item.question}</span>
                  {openItems[`${activeTab}-${index}`] ? (
                    <ChevronUp className="w-5 h-5 text-gray-500" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-500" />
                  )}
                </button>
                
                {openItems[`${activeTab}-${index}`] && (
                  <div className="px-6 pb-4">
                    {Array.isArray(item.answer) ? (
                      <ul className="list-disc list-inside space-y-2 text-gray-600">
                        {item.answer.map((point, i) => (
                          <li key={i}>{point}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-600">{item.answer}</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Contact Section */}
          <div className="mt-12 text-center">
            <p className="text-gray-600">
              Vous n'avez pas trouvé la réponse à votre question ?
            </p>
            <a
              href="mailto:info@emoting.fr"
              className="text-orange-500 hover:text-orange-600 font-medium"
            >
              Contactez-nous
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}