import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createMeal } from '../lib/database';
import Button from '../components/ui/Button';
import ImageUpload from '../components/ui/ImageUpload';
import Confetti from '../components/ui/Confetti';
import toast from 'react-hot-toast';

// Liste des ingrédients communs
const COMMON_INGREDIENTS = [
  // Légumes
  'Tomates', 'Oignons', 'Ail', 'Carottes', 'Poivrons', 'Courgettes', 'Aubergines',
  'Pommes de terre', 'Champignons', 'Épinards', 'Salade', 'Concombre', 'Brocolis',
  // Protéines
  'Poulet', 'Bœuf', 'Porc', 'Saumon', 'Thon', 'Crevettes', 'Œufs', 'Tofu',
  // Féculents
  'Riz', 'Pâtes', 'Quinoa', 'Couscous', 'Pain', 'Lentilles',
  // Produits laitiers
  'Fromage', 'Crème', 'Beurre', 'Lait', 'Yaourt',
  // Herbes et épices
  'Basilic', 'Persil', 'Coriandre', 'Thym', 'Romarin', 'Cumin', 'Paprika',
  // Autres
  'Huile d\'olive', 'Citron', 'Miel', 'Sauce soja', 'Vinaigre balsamique'
];

// Liste des allergènes communs
const COMMON_ALLERGENS = [
  'Gluten',
  'Crustacés',
  'Œufs',
  'Poisson',
  'Arachides',
  'Soja',
  'Lait',
  'Fruits à coque',
  'Céleri',
  'Moutarde',
  'Graines de sésame',
  'Sulfites',
  'Lupin',
  'Mollusques'
];

// Catégories de plats
const MEAL_CATEGORIES = [
  { value: 'french', label: 'Français' },
  { value: 'italian', label: 'Italien' },
  { value: 'asian', label: 'Asiatique' },
  { value: 'mexican', label: 'Mexicain' },
  { value: 'indian', label: 'Indien' },
  { value: 'mediterranean', label: 'Méditerranéen' },
  { value: 'japanese', label: 'Japonais' },
  { value: 'thai', label: 'Thaïlandais' },
  { value: 'lebanese', label: 'Libanais' },
  { value: 'vegan', label: 'Végétalien' },
  { value: 'vegetarian', label: 'Végétarien' },
  { value: 'healthy', label: 'Healthy' },
  { value: 'dessert', label: 'Dessert' }
];

export default function CreateMealPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
  const [selectedAllergens, setSelectedAllergens] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    portions_available: '',
    category: '',
    preparation_time: '',
    available_date: '',
    image_url: '',
    cookingDetails: '', // Détails supplémentaires sur la préparation
    servingSuggestions: '', // Suggestions de service
    dietaryInfo: '', // Informations diététiques
    storageInstructions: '' // Instructions de conservation
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    if (selectedIngredients.length === 0) {
      toast.error('Veuillez sélectionner au moins un ingrédient');
      return;
    }

    try {
      setLoading(true);
      await createMeal({
        cook_id: user.user_id,
        name: formData.name,
        description: formData.description,
        price: parseFloat(formData.price),
        portions_available: parseInt(formData.portions_available),
        ingredients: selectedIngredients,
        category: formData.category,
        preparation_time: parseInt(formData.preparation_time),
        allergens: selectedAllergens,
        available_date: new Date(formData.available_date),
        image_url: formData.image_url,
        cooking_details: formData.cookingDetails,
        serving_suggestions: formData.servingSuggestions,
        dietary_info: formData.dietaryInfo,
        storage_instructions: formData.storageInstructions
      });
      
      setShowConfetti(true);
      toast.success('Plat créé avec succès !');
      
      setTimeout(() => {
        navigate('/cook/dashboard');
      }, 2000);
    } catch (error) {
      console.error('Error creating meal:', error);
      toast.error('Erreur lors de la création du plat');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageUploaded = (url: string) => {
    setFormData(prev => ({ ...prev, image_url: url }));
  };

  const toggleIngredient = (ingredient: string) => {
    setSelectedIngredients(prev => 
      prev.includes(ingredient)
        ? prev.filter(i => i !== ingredient)
        : prev.length < 40
          ? [...prev, ingredient]
          : prev
    );
  };

  const toggleAllergen = (allergen: string) => {
    setSelectedAllergens(prev =>
      prev.includes(allergen)
        ? prev.filter(a => a !== allergen)
        : [...prev, allergen]
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {showConfetti && <Confetti />}
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Créer un nouveau plat</h1>
      
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">Photo du plat</label>
          <ImageUpload
            onImageUploaded={handleImageUploaded}
            currentImage={formData.image_url}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Nom du plat</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Décrivez votre plat, ses saveurs, et ce qui le rend unique..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Catégorie</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option value="">Sélectionnez une catégorie</option>
            {MEAL_CATEGORIES.map(category => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Prix</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
              min="0"
              step="0.01"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Portions disponibles</label>
            <input
              type="number"
              name="portions_available"
              value={formData.portions_available}
              onChange={handleChange}
              required
              min="1"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Ingrédients ({selectedIngredients.length}/40)
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COMMON_INGREDIENTS.map((ingredient) => (
              <label
                key={ingredient}
                className={`flex items-center p-2 rounded-md cursor-pointer ${
                  selectedIngredients.includes(ingredient)
                    ? 'bg-orange-100 text-orange-800'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                <input
                  type="checkbox"
                  checked={selectedIngredients.includes(ingredient)}
                  onChange={() => toggleIngredient(ingredient)}
                  className="mr-2"
                />
                {ingredient}
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Allergènes
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {COMMON_ALLERGENS.map((allergen) => (
              <label
                key={allergen}
                className={`flex items-center p-2 rounded-md cursor-pointer ${
                  selectedAllergens.includes(allergen)
                    ? 'bg-red-100 text-red-800'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                <input
                  type="checkbox"
                  checked={selectedAllergens.includes(allergen)}
                  onChange={() => toggleAllergen(allergen)}
                  className="mr-2"
                />
                {allergen}
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Temps de préparation (minutes)</label>
          <input
            type="number"
            name="preparation_time"
            value={formData.preparation_time}
            onChange={handleChange}
            required
            min="1"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Date de disponibilité</label>
          <input
            type="datetime-local"
            name="available_date"
            value={formData.available_date}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Détails de préparation</label>
          <textarea
            name="cookingDetails"
            value={formData.cookingDetails}
            onChange={handleChange}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Décrivez les étapes de préparation, les techniques utilisées..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Suggestions de service</label>
          <textarea
            name="servingSuggestions"
            value={formData.servingSuggestions}
            onChange={handleChange}
            rows={2}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Comment servir et accompagner ce plat..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Informations diététiques</label>
          <textarea
            name="dietaryInfo"
            value={formData.dietaryInfo}
            onChange={handleChange}
            rows={2}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Valeurs nutritionnelles, régimes spéciaux..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Instructions de conservation</label>
          <textarea
            name="storageInstructions"
            value={formData.storageInstructions}
            onChange={handleChange}
            rows={2}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Comment conserver et réchauffer le plat..."
          />
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => navigate('/cook/dashboard')}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            disabled={loading}
            isLoading={loading}
          >
            {loading ? 'Création...' : 'Créer le plat'}
          </Button>
        </div>
      </form>
    </div>
  );
}