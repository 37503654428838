import Button from '../ui/Button';

interface CartSummaryProps {
  subtotal: number;
  onCheckout: () => void;
  isProcessing: boolean;
}

export default function CartSummary({
  subtotal,
  onCheckout,
  isProcessing
}: CartSummaryProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-4">Résumé de la commande</h2>
      
      <div className="space-y-4">
        <div className="flex justify-between">
          <span>Sous-total</span>
          <span>{subtotal.toFixed(2)}€</span>
        </div>

        <div className="border-t pt-4">
          <div className="flex justify-between text-lg font-semibold">
            <span>Total</span>
            <span>{subtotal.toFixed(2)}€</span>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Retrait sur place uniquement. La livraison sera bientôt disponible.
          </p>
        </div>

        <Button
          onClick={onCheckout}
          className="w-full"
          isLoading={isProcessing}
        >
          Procéder au paiement
        </Button>
      </div>
    </div>
  );
}