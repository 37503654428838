import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useCart } from '../contexts/CartContext';
import { useAuth } from '../contexts/AuthContext';
import { createPaymentIntent } from '../lib/stripe';
import { createOrder } from '../lib/database';
import Button from '../components/ui/Button';
import Confetti from '../components/ui/Confetti';
import toast from 'react-hot-toast';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      fontFamily: 'Roboto, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '#424770',
      },
    },
    invalid: {
      color: '#9e2146',
      ':focus': {
        color: '#9e2146',
      },
    },
  },
  hidePostalCode: true
};

// Test card for development
const TEST_CARD = {
  number: '4242 4242 4242 4242',
  exp_month: 12,
  exp_year: 2024,
  cvc: '123'
};

export default function CheckoutPage() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const { cartItems, clearCart } = useCart();
  const [checkoutData, setCheckoutData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const storedData = sessionStorage.getItem('checkout_data');
    if (!storedData) {
      toast.error('Données de paiement invalides');
      navigate('/cart');
      return;
    }

    try {
      const parsedData = JSON.parse(storedData);
      setCheckoutData(parsedData);
    } catch (error) {
      console.error('Error parsing checkout data:', error);
      toast.error('Erreur lors du chargement des données');
      navigate('/cart');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (!stripe || !elements || !checkoutData || !user) {
      return;
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      setError('Could not find card element');
      return;
    }

    try {
      setProcessing(true);

      // Create payment intent (amount in cents)
      const { clientSecret } = await createPaymentIntent(Math.round(checkoutData.total * 100));

      // Confirm card payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            email: user.email,
            name: user.name,
          },
        },
      });

      if (stripeError) {
        setError(stripeError.message || 'An error occurred during payment');
        toast.error(stripeError.message || 'Erreur lors du paiement');
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        // Create order in database
        await createOrder(
          {
            consumer_id: user.user_id,
            cook_id: cartItems[0].meal.cook_id,
            total_amount: checkoutData.total,
            order_status: 'pending',
            delivery_option: 'pickup',
          },
          cartItems
        );

        await clearCart();
        sessionStorage.removeItem('checkout_data');
        setShowConfetti(true);
        toast.success('Commande confirmée !');
        
        setTimeout(() => {
          navigate('/orders');
        }, 2000);
      }
    } catch (error: any) {
      console.error('Payment error:', error);
      setError(error.message || 'An error occurred during payment');
      toast.error(error.message || 'Erreur lors du paiement');
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4285f4]"></div>
      </div>
    );
  }

  if (!checkoutData) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h2 className="text-2xl font-bold mb-4">Données de paiement invalides</h2>
        <Button onClick={() => navigate('/cart')}>Retourner au panier</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      {showConfetti && <Confetti />}
      
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Paiement</h1>

        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">Résumé de la commande</h2>
          
          {cartItems.map((item) => (
            <div key={item.cart_item_id} className="flex justify-between py-2">
              <span>{item.meal.name} x {item.quantity}</span>
              <span>{(item.meal.price * item.quantity).toFixed(2)}€</span>
            </div>
          ))}
          
          <div className="border-t mt-4 pt-4">
            <div className="flex justify-between text-lg font-semibold">
              <span>Total</span>
              <span>{checkoutData.total.toFixed(2)}€</span>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Retrait sur place uniquement
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Informations de paiement</h3>
            <div className="p-4 border rounded-lg">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}
            <p className="mt-4 text-sm text-gray-500">
              Pour tester, utilisez la carte : {TEST_CARD.number}
            </p>
          </div>

          <Button
            type="submit"
            disabled={!stripe || processing}
            isLoading={processing}
            className="w-full"
          >
            {processing ? 'Traitement...' : `Payer ${checkoutData.total.toFixed(2)}€`}
          </Button>
        </form>
      </div>
    </div>
  );
}