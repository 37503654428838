import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import { ChefHat, Mail } from 'lucide-react';
import toast from 'react-hot-toast';

export default function AuthPage() {
  const navigate = useNavigate();
  const { signIn, signUp } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    username: '',
    userType: 'consumer',
    location: '',
    bio: '',
    dietaryPreferences: [] as string[],
    specialties: [] as string[],
    availability: [] as string[],
  });

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        await signIn(formData.email, formData.password);
        toast.success('Bienvenue !');
        navigate('/');
      } else {
        if (formData.username.length < 3) {
          toast.error('Le pseudo doit contenir au moins 3 caractères');
          return;
        }

        await signUp(formData.email, formData.password, {
          name: formData.name,
          username: formData.username,
          email: formData.email,
          user_type: formData.userType as 'cook' | 'consumer',
          location: formData.location,
          bio: formData.bio,
          dietary_preferences: formData.dietaryPreferences,
          specialties: formData.specialties,
          availability: formData.availability,
        });
        toast.success('Compte créé avec succès !');
        navigate('/');
      }
    } catch (error: any) {
      console.error('Auth error:', error);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Email ou mot de passe invalide');
      } else {
        toast.error('Erreur d\'authentification');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4 py-12 bg-gray-50">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <ChefHat className="mx-auto h-12 w-12 text-orange-500" />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            {isLogin ? 'Bienvenue' : 'Créer un compte'}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {isLogin ? "Pas encore de compte ? " : 'Déjà un compte ? '}
            <button
              onClick={() => setIsLogin(!isLogin)}
              className="font-medium text-orange-500 hover:text-orange-400"
            >
              {isLogin ? 'Créer un compte' : 'Se connecter'}
            </button>
          </p>
        </div>

        <form onSubmit={handleEmailAuth} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          {!isLogin && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">Nom complet</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Pseudo</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  minLength={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Je souhaite</label>
                <select
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                >
                  <option value="consumer">Commander des repas</option>
                  <option value="cook">Vendre des repas</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Localisation</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>
            </>
          )}

          <Button
            type="submit"
            className="w-full"
            isLoading={loading}
          >
            <Mail className="w-5 h-5 mr-2" />
            {isLogin ? 'Se connecter' : 'Créer un compte'}
          </Button>
        </form>
      </div>
    </div>
  );
}