import Hero from '../components/home/Hero';
import FeaturedMeals from '../components/home/FeaturedMeals';
import HowItWorks from '../components/home/HowItWorks';
import PopularCooks from '../components/home/PopularCooks';

export default function HomePage() {
  return (
    <div className="space-y-16">
      <Hero />
      <FeaturedMeals />
      <HowItWorks />
      <PopularCooks />
    </div>
  );
}