export const fr = {
  common: {
    loading: 'Chargement...',
    error: 'Une erreur est survenue',
    success: 'Succès',
    cancel: 'Annuler',
    save: 'Enregistrer',
    edit: 'Modifier',
    delete: 'Supprimer',
    search: 'Rechercher',
    start: 'Commencer'
  },

  header: {
    searchPlaceholder: 'Rechercher des plats...',
    browseMeals: 'Parcourir les plats',
    cookDashboard: 'Tableau de bord Cuisinier',
    myOrders: 'Mes commandes',
    cart: 'Panier',
    profile: 'Profil'
  },

  home: {
    hero: {
      title: 'Découvrez des plats faits maison authentiques près de chez vous',
      subtitle: 'Rejoignez notre communauté de passionnés de cuisine. Commandez des plats délicieux ou partagez vos créations culinaires.',
      exploreMeals: 'Explorer les plats',
      startCooking: 'Commencer à cuisiner'
    },
    howItWorks: {
      title: 'Comment ça marche',
      subtitle: 'Commencez avec BlablaFood en trois étapes simples',
      steps: [
        {
          title: 'Trouvez des Cuisiniers Locaux',
          description: 'Découvrez des cuisiniers talentueux dans votre quartier proposant de délicieux plats faits maison.'
        },
        {
          title: 'Passez Votre Commande',
          description: 'Parcourez les menus, sélectionnez vos plats préférés et commandez en quelques clics.'
        },
        {
          title: 'Savourez',
          description: 'Récupérez votre repas fraîchement préparé ou faites-vous livrer à domicile.'
        }
      ]
    },
    popularCooks: {
      title: 'Cuisiniers Populaires',
      subtitle: 'Découvrez nos meilleurs chefs à domicile'
    },
    featuredMeals: {
      title: 'Plats à la Une',
      viewAll: 'Voir tout',
      noMeals: 'Aucun plat disponible pour le moment',
      browseAll: 'Parcourir tous les plats',
      onlyLeft: 'Plus que {count} disponibles !',
      by: 'par'
    }
  },

  cookDashboard: {
    title: 'Tableau de Bord Cuisinier',
    tabs: {
      overview: 'Aperçu',
      orders: 'Commandes',
      meals: 'Plats',
      reviews: 'Avis',
      analytics: 'Analyses'
    },
    stats: {
      monthlyEarnings: 'Revenus mensuels',
      pendingOrders: 'Commandes en attente',
      totalCustomers: 'Clients totaux',
      averageRating: 'Note moyenne'
    },
    meals: {
      title: 'Vos Plats',
      addNew: 'Ajouter un plat',
      addFirst: 'Ajouter votre premier plat',
      empty: 'Vous n\'avez pas encore de plats',
      deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce plat ?',
      deleteSuccess: 'Plat supprimé avec succès',
      deleteError: 'Erreur lors de la suppression du plat'
    },
    orders: {
      title: 'Commandes',
      empty: 'Aucune commande pour le moment',
      status: {
        pending: 'En attente',
        confirmed: 'Confirmée',
        preparing: 'En préparation',
        ready: 'Prête',
        delivered: 'Livrée',
        cancelled: 'Annulée'
      }
    },
    reviews: {
      title: 'Avis',
      empty: 'Aucun avis pour le moment'
    }
  },

  auth: {
    signIn: 'Se connecter',
    signUp: 'S\'inscrire',
    signOut: 'Se déconnecter',
    email: 'Adresse email',
    password: 'Mot de passe',
    name: 'Nom complet',
    username: 'Pseudo',
    location: 'Localisation',
    userType: {
      label: 'Je souhaite',
      consumer: 'Commander des repas',
      cook: 'Vendre des repas'
    },
    welcomeBack: 'Bon retour',
    createAccount: 'Créer un compte',
    noAccount: 'Pas encore de compte ?',
    hasAccount: 'Déjà un compte ?'
  },

  profile: {
    title: 'Profil',
    personalInfo: {
      title: 'Informations personnelles',
      name: 'Nom complet',
      username: 'Pseudo',
      email: 'Email',
      phone: 'Téléphone',
      location: 'Adresse',
      edit: 'Modifier',
      updateSuccess: 'Profil mis à jour avec succès',
      updateError: 'Erreur lors de la mise à jour du profil',
      password: {
        change: 'Changer le mot de passe',
        cancel: 'Annuler le changement',
        new: 'Nouveau mot de passe',
        confirm: 'Confirmer le mot de passe',
        mismatch: 'Les mots de passe ne correspondent pas',
        success: 'Mot de passe modifié avec succès',
        error: 'Erreur lors du changement de mot de passe'
      }
    }
  },

  meals: {
    title: 'Plats Disponibles',
    filters: {
      category: {
        label: 'Catégorie',
        all: 'Toutes les catégories',
        italian: 'Italien',
        asian: 'Asiatique',
        mexican: 'Mexicain',
        indian: 'Indien',
        desserts: 'Desserts'
      },
      priceRange: {
        label: 'Fourchette de prix',
        all: 'Tous les prix',
        under10: 'Moins de 10€',
        '10-20': '10€ - 20€',
        '20-30': '20€ - 30€',
        over30: 'Plus de 30€'
      },
      availability: {
        label: 'Disponibilité',
        all: 'Tout',
        available: 'Disponible maintenant',
        today: 'Disponible aujourd\'hui',
        tomorrow: 'Disponible demain'
      }
    },
    soldOut: 'Épuisé',
    preparationTime: 'Temps de préparation',
    portions: 'portions restantes',
    addToCart: 'Ajouter au panier',
    details: {
      ingredients: 'Ingrédients',
      allergens: 'Allergènes',
      by: 'par'
    }
  },

  cart: {
    title: 'Votre Panier',
    empty: 'Votre panier est vide',
    signInPrompt: 'Veuillez vous connecter pour voir votre panier',
    browseMeals: 'Parcourir les plats',
    summary: {
      title: 'Résumé de la commande',
      subtotal: 'Sous-total',
      total: 'Total',
      delivery: {
        title: 'Option de livraison',
        pickup: 'Retrait (Gratuit)',
        delivery: 'Livraison (5€)',
        address: 'Adresse de livraison'
      }
    },
    checkout: 'Procéder au paiement'
  },

  checkout: {
    title: 'Paiement',
    orderSummary: 'Résumé de la commande',
    deliveryAddress: 'Adresse de livraison :',
    paymentInfo: 'Informations de paiement',
    confirmOrder: 'Confirmer la commande',
    orderConfirmed: 'Commande confirmée !',
    orderError: 'Erreur lors de la création de la commande',
    paymentError: 'Erreur de paiement'
  },

  orders: {
    title: 'Vos Commandes',
    noOrders: 'Vous n\'avez pas encore passé de commande',
    status: {
      pending: 'En attente',
      confirmed: 'Confirmée',
      preparing: 'En préparation',
      ready: 'Prête',
      delivered: 'Livrée',
      cancelled: 'Annulée'
    }
  },

  footer: {
    quickLinks: {
      title: 'Liens Rapides',
      browseMeals: 'Parcourir les plats',
      becomeCook: 'Devenir Cuisinier',
      aboutUs: 'À propos'
    },
    support: {
      title: 'Support',
      faq: 'FAQ',
      contact: 'Contact',
      privacy: 'Politique de confidentialité'
    },
    copyright: '© {year} BlablaFood. Tous droits réservés.'
  }
};