import { Utensils, ShoppingBag, ChefHat } from 'lucide-react';
import { useTranslation } from '../../contexts/TranslationContext';

export default function HowItWorks() {
  const { home } = useTranslation();

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">{home.howItWorks.title}</h2>
          <p className="mt-4 text-lg text-gray-600">
            {home.howItWorks.subtitle}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {home.howItWorks.steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-orange-100 text-orange-500 mb-6">
                {index === 0 && <ChefHat className="w-8 h-8" />}
                {index === 1 && <ShoppingBag className="w-8 h-8" />}
                {index === 2 && <Utensils className="w-8 h-8" />}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}