import { Trash2 } from 'lucide-react';
import type { CartItem as CartItemType, Meal } from '../../types/database';

interface CartItemProps {
  item: CartItemType & { meal: Meal };
  onQuantityChange: (cartItemId: string, quantity: number) => Promise<void>;
  onRemove: (cartItemId: string) => Promise<void>;
}

export default function CartItem({ item, onQuantityChange, onRemove }: CartItemProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 flex items-center gap-4">
      <img
        src={item.meal.image_url || "https://images.unsplash.com/photo-1546069901-ba9599a7e63c"}
        alt={item.meal.name}
        className="w-24 h-24 object-cover rounded-lg"
      />
      
      <div className="flex-grow">
        <h3 className="font-semibold text-gray-900">{item.meal.name}</h3>
        <p className="text-gray-600">{item.meal.price.toFixed(2)}€</p>
        
        <div className="flex items-center mt-2">
          <button
            onClick={() => onQuantityChange(item.cart_item_id, item.quantity - 1)}
            className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300"
          >
            -
          </button>
          <span className="mx-4">{item.quantity}</span>
          <button
            onClick={() => onQuantityChange(item.cart_item_id, item.quantity + 1)}
            className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300"
            disabled={item.quantity >= item.meal.portions_available}
          >
            +
          </button>
        </div>
      </div>

      <div className="text-right">
        <p className="font-semibold text-gray-900">
          {(item.meal.price * item.quantity).toFixed(2)}€
        </p>
        <button
          onClick={() => onRemove(item.cart_item_id)}
          className="text-red-500 hover:text-red-600 mt-2"
        >
          <Trash2 className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
}