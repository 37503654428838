import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import ImageUpload from '../components/ui/ImageUpload';
import type { Meal } from '../types/database';
import toast from 'react-hot-toast';

export default function MealEditPage() {
  const { mealId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [meal, setMeal] = useState<Meal | null>(null);

  useEffect(() => {
    const loadMeal = async () => {
      if (!mealId) return;

      try {
        const mealRef = doc(db, 'meals', mealId);
        const mealSnap = await getDoc(mealRef);
        
        if (mealSnap.exists()) {
          const mealData = { ...mealSnap.data(), meal_id: mealSnap.id } as Meal;
          
          // Verify ownership
          if (mealData.cook_id !== user?.user_id) {
            toast.error('Vous n\'êtes pas autorisé à modifier ce plat');
            navigate('/cook/dashboard');
            return;
          }
          
          setMeal(mealData);
        } else {
          toast.error('Plat non trouvé');
          navigate('/cook/dashboard');
        }
      } catch (error) {
        console.error('Error loading meal:', error);
        toast.error('Erreur lors du chargement du plat');
      } finally {
        setLoading(false);
      }
    };

    loadMeal();
  }, [mealId, navigate, user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!meal || !mealId) return;

    try {
      setSaving(true);
      const mealRef = doc(db, 'meals', mealId);
      await updateDoc(mealRef, {
        ...meal,
        updated_at: new Date()
      });

      toast.success('Plat mis à jour avec succès');
      navigate('/cook/dashboard');
    } catch (error) {
      console.error('Error updating meal:', error);
      toast.error('Erreur lors de la mise à jour du plat');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setMeal(prev => prev ? { ...prev, [name]: value } : null);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (!meal) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Plat non trouvé</h1>
        <Button onClick={() => navigate('/cook/dashboard')}>
          Retour au tableau de bord
        </Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Modifier le plat</h1>

      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">Photo du plat</label>
          <ImageUpload
            onImageUploaded={(url) => setMeal(prev => prev ? { ...prev, image_url: url } : null)}
            currentImage={meal.image_url}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Nom du plat</label>
          <input
            type="text"
            name="name"
            value={meal.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            value={meal.description}
            onChange={handleChange}
            required
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Prix</label>
            <input
              type="number"
              name="price"
              value={meal.price}
              onChange={handleChange}
              required
              min="0"
              step="0.01"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Portions disponibles</label>
            <input
              type="number"
              name="portions_available"
              value={meal.portions_available}
              onChange={handleChange}
              required
              min="0"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => navigate('/cook/dashboard')}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            isLoading={saving}
          >
            Enregistrer les modifications
          </Button>
        </div>
      </form>
    </div>
  );
}