import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { updateUser } from '../lib/database';
import Button from '../components/ui/Button';
import { User, MapPin, Mail, Phone, Lock, ChefHat, AtSign, UserCog, Edit2, Camera } from 'lucide-react';
import toast from 'react-hot-toast';
import ImageUpload from '../components/ui/ImageUpload';
import ChefProfileSection from '../components/profile/ChefProfileSection';

export default function ProfilePage() {
  const navigate = useNavigate();
  const { user, refreshUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changingUserType, setChangingUserType] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    location: '',
    email: '',
    phone: '',
    bio: '',
    avatar_url: '',
    specialties: [] as string[],
    availability: [] as string[],
    social_links: {
      instagram: '',
      facebook: '',
      twitter: ''
    }
  });

  useEffect(() => {
    if (user) {
      setFormData(prev => ({
        ...prev,
        name: user.name || '',
        username: user.username || '',
        location: user.location || '',
        email: user.email || '',
        bio: user.bio || '',
        avatar_url: user.avatar_url || '',
        specialties: user.specialties || [],
        availability: user.availability || []
      }));
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);

      if (formData.username.length < 3) {
        toast.error('Le pseudo doit contenir au moins 3 caractères');
        return;
      }

      await updateUser(user.user_id, {
        name: formData.name,
        username: formData.username,
        location: formData.location,
        bio: formData.bio,
        avatar_url: formData.avatar_url,
        updated_at: new Date()
      });

      toast.success('Profil mis à jour avec succès');
      setEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Erreur lors de la mise à jour du profil');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageUploaded = (url: string) => {
    setFormData(prev => ({ ...prev, avatar_url: url }));
  };

  const handleUserTypeChange = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const newType = user.user_type === 'consumer' ? 'cook' : 'consumer';
      
      await updateUser(user.user_id, {
        user_type: newType,
        updated_at: new Date()
      });

      // Refresh user data
      await refreshUser();
      
      toast.success(`Vous êtes maintenant ${newType === 'cook' ? 'cuisinier' : 'client'} !`);
      setChangingUserType(false);
      
      // Navigate based on new user type
      if (newType === 'cook') {
        navigate('/cook/dashboard');
      } else {
        navigate('/meals');
      }
    } catch (error) {
      console.error('Error changing user type:', error);
      toast.error('Erreur lors du changement de statut');
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Veuillez vous connecter pour accéder à votre profil
          </h1>
          <Button onClick={() => navigate('/auth')}>
            Se connecter
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="relative h-48 bg-gradient-to-r from-[#4285f4] to-[#34a853]">
            <div className="absolute -bottom-16 left-8">
              <div className="relative">
                <img
                  src={formData.avatar_url || `https://ui-avatars.com/api/?name=${user.name}&background=random`}
                  alt={user.name}
                  className="w-32 h-32 rounded-full border-4 border-white object-cover"
                />
                {editing && (
                  <div className="absolute bottom-0 right-0">
                    <ImageUpload
                      onImageUploaded={handleImageUploaded}
                      currentImage={formData.avatar_url}
                    >
                      <button className="p-2 bg-white rounded-full shadow-md">
                        <Camera className="w-5 h-5 text-gray-600" />
                      </button>
                    </ImageUpload>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-20 px-8 pb-8">
            <div className="flex justify-between items-start mb-6">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{user.name}</h1>
                <p className="text-gray-600">@{user.username}</p>
              </div>
              {!editing && !changingUserType && (
                <Button onClick={() => setEditing(true)} variant="outline">
                  <Edit2 className="w-4 h-4 mr-2" />
                  Modifier
                </Button>
              )}
            </div>

            {editing ? (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom complet
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Pseudo
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    minLength={3}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Localisation
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Bio
                  </label>
                  <textarea
                    name="bio"
                    value={formData.bio}
                    onChange={handleChange}
                    rows={4}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#4285f4] focus:ring-[#4285f4]"
                    placeholder="Parlez-nous de vous..."
                  />
                </div>

                <div className="flex justify-end space-x-4">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setEditing(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    isLoading={loading}
                  >
                    Enregistrer
                  </Button>
                </div>
              </form>
            ) : (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Mail className="w-5 h-5" />
                      <span>{user.email}</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-600">
                      <MapPin className="w-5 h-5" />
                      <span>{user.location}</span>
                    </div>
                    {user.bio && (
                      <div className="flex items-start space-x-2 text-gray-600">
                        <User className="w-5 h-5 mt-1" />
                        <p>{user.bio}</p>
                      </div>
                    )}
                  </div>

                  <div className="space-y-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-gray-900 mb-2">Statut</h3>
                      <div className="flex items-center space-x-2">
                        {user.user_type === 'cook' ? (
                          <ChefHat className="w-5 h-5 text-[#4285f4]" />
                        ) : (
                          <User className="w-5 h-5 text-[#4285f4]" />
                        )}
                        <span className="capitalize">{user.user_type}</span>
                      </div>
                    </div>

                    <Button
                      onClick={() => setChangingUserType(true)}
                      variant="outline"
                      className="w-full"
                    >
                      <UserCog className="w-4 h-4 mr-2" />
                      {user.user_type === 'cook' ? 'Devenir client' : 'Devenir cuisinier'}
                    </Button>
                  </div>
                </div>

                {changingUserType && (
                  <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                    <p className="text-[#4285f4] mb-4">
                      {user.user_type === 'consumer'
                        ? "Vous allez devenir cuisinier. Vous pourrez créer et vendre vos plats."
                        : "Vous allez redevenir client. Vous ne pourrez plus vendre de plats."}
                    </p>
                    <div className="flex justify-end space-x-4">
                      <Button
                        variant="outline"
                        onClick={() => setChangingUserType(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        onClick={handleUserTypeChange}
                        isLoading={loading}
                      >
                        Confirmer
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {user.user_type === 'cook' && (
          <div className="mt-8">
            <ChefProfileSection 
              user={user}
              onUpdate={async (data) => {
                try {
                  await updateUser(user.user_id, {
                    ...data,
                    updated_at: new Date()
                  });
                  toast.success('Profil cuisinier mis à jour avec succès');
                } catch (error) {
                  console.error('Error updating chef profile:', error);
                  toast.error('Erreur lors de la mise à jour du profil');
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}