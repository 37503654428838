import { useEffect } from 'react';
import { Heart, Users, Leaf, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-orange-50 to-orange-100 py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              À Propos de BlablaFood
            </h1>
            <p className="text-xl text-gray-600">
              BlablaFood est bien plus qu'une simple plateforme de partage de repas ; c'est une nouvelle façon de connecter 
              les voisins à travers la cuisine.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto space-y-16">
          {/* Introduction */}
          <div className="prose prose-orange max-w-none">
            <p className="text-lg text-gray-600 leading-relaxed">
              Inspirée par le modèle de l'économie collaborative, BlablaFood permet aux passionnés de cuisine de partager 
              leurs créations avec les membres de leur communauté, et aux voisins d'accéder à des repas faits maison, 
              authentiques et abordables. Dans un monde où tout va de plus en plus vite, nous souhaitons rendre 
              l'alimentation plus humaine et plus locale, en faisant de chaque repas une opportunité de connexion.
            </p>
          </div>

          {/* Vision Section */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
              <Heart className="w-8 h-8 text-orange-500 mr-3" />
              Notre Vision : Créer des Communautés Grâce à la Cuisine
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Chez BlablaFood, nous croyons au pouvoir de la cuisine pour rassembler les gens. Nous envisageons un monde 
              où chaque quartier devient une communauté soudée, où chacun peut redécouvrir le plaisir de manger sainement, 
              sans contraintes, tout en tissant des liens avec ses voisins. Grâce à BlablaFood, nous facilitons les échanges 
              de repas faits maison, préparés avec amour, pour donner aux gens une alternative conviviale aux repas 
              industriels et aux chaînes de restauration impersonnelles.
            </p>
          </div>

          {/* Values Section */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
              <Users className="w-8 h-8 text-orange-500 mr-3" />
              Ce Qui Nous Rassemble : Authenticité, Confiance et Proximité
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Nous nous engageons à offrir une plateforme de confiance où l'authenticité est au cœur de chaque transaction. 
              BlablaFood s'adresse à tous ceux qui recherchent la qualité et la simplicité d'un bon plat maison, ainsi 
              qu'aux cuisiniers qui souhaitent partager leurs talents. Nos utilisateurs peuvent être certains de trouver 
              des repas préparés avec soin et de découvrir de nouvelles saveurs, le tout dans une démarche de transparence 
              et de bienveillance.
            </p>
          </div>

          {/* Sustainability Section */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
              <Leaf className="w-8 h-8 text-orange-500 mr-3" />
              Une Démarche Responsable et Durable
            </h2>
            <p className="text-gray-600 leading-relaxed">
              BlablaFood est également un acteur du changement en matière de durabilité. En facilitant la préparation de 
              repas en plus grandes quantités, nous aidons à réduire le gaspillage alimentaire et favorisons une 
              consommation plus responsable. Les ingrédients locaux et les pratiques culinaires durables sont mis en 
              avant pour que chaque assiette soit à la fois un plaisir et un geste pour la planète.
            </p>
          </div>

          {/* Join Section */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
              <Share2 className="w-8 h-8 text-orange-500 mr-3" />
              Rejoignez Le Mouvement
            </h2>
            <p className="text-gray-600 leading-relaxed mb-8">
              BlablaFood est une invitation à se réapproprier la cuisine, à partager des moments simples et vrais avec ses 
              voisins, et à faire partie d'une communauté qui valorise le partage et l'entraide. Rejoignez-nous dans cette 
              aventure culinaire pour réinventer le lien de voisinage, un repas à la fois. Nous croyons qu'à travers 
              BlablaFood, nous pouvons tous redécouvrir la puissance de la convivialité et de la solidarité autour de la table.
            </p>

            <div className="text-center">
              <p className="text-xl font-medium text-gray-900 mb-6">
                BlablaFood – Parce que chaque repas partagé est un pas vers une communauté plus proche, plus humaine, 
                et plus inspirante.
              </p>
              <Link to="/auth">
                <Button size="lg">
                  Rejoindre la communauté
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}